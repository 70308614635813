import { useCallback, useEffect } from 'react';

import { IPromotion } from 'api/models/responses/promotions/promotion';
import {
	SortPromotionsLabels,
	PaginatePromotionsParams,
} from 'api/models/requests/promotions/paginatePromotionsParams';

import { SortParamsResult, useSortParams } from 'hooks/useSortParams';
import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useAppSelector } from 'hooks/redux/useAppSelector';

import { SKIP, TAKE } from 'constants/general/generalGetDataParams';
import {
	getPromotionsAsync,
	promotionsSelector,
	isPromotionsPendingSelector,
	isPromotionsPaginationPendingSelector,
} from 'store/promotions';

interface IPaginatePromotionsResult
	extends SortParamsResult<SortPromotionsLabels> {
	promotions: IPromotion[];
	isPromotionsPending: boolean;
	getPaginatedPromotions: () => void;
	isPromotionsPaginationPending: boolean;
}

export const usePaginatePromotions = (): IPaginatePromotionsResult => {
	const isPromotionsPending = useAppSelector(isPromotionsPendingSelector);
	const isPromotionsPaginationPending = useAppSelector(
		isPromotionsPaginationPendingSelector
	);
	const promotions = useAppSelector(promotionsSelector);

	const { orderBy, setSortParams, orderDirection } =
		useSortParams<SortPromotionsLabels>({
			defaultOrderBy: SortPromotionsLabels.Name,
		});

	const dispatch = useAppDispatch();

	const getPromotions = useCallback(() => {
		const params: PaginatePromotionsParams = {
			orderBy,
			skip: SKIP,
			take: TAKE,
			orderDirection,
		};

		void dispatch(getPromotionsAsync(params));
	}, [orderBy, orderDirection]);

	const getPaginatedPromotions = useCallback(() => {
		const isPaginationLimit =
			(promotions.totalCount === promotions.results.length &&
				promotions.totalCount) ||
			promotions.totalCount < TAKE;

		if (isPromotionsPaginationPending || isPaginationLimit) return;

		const params: PaginatePromotionsParams = {
			orderBy,
			take: TAKE,
			orderDirection,
			skip: promotions.results.length,
		};

		void dispatch(getPromotionsAsync(params));
	}, [orderBy, orderDirection]);

	useEffect(() => {
		getPromotions();
	}, [getPromotions]);

	return {
		orderBy,
		setSortParams,
		orderDirection,
		isPromotionsPending,
		getPaginatedPromotions,
		isPromotionsPaginationPending,
		promotions: promotions.results,
	};
};
