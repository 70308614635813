import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { validateForUniquenessSubjectFormQuestions } from 'utils/subjectFormQuestions/validateForUniquenessSubjectFormQuestions';
import { changeEntitiesSequence } from 'utils/dragAndDrop/changeEntitiesSequence';
import {
	validateOptions,
	validateFormQuestion,
	validateDataFieldType,
} from 'utils/validations/general/formQuestionsValidation';

import { ISubjectFormQuestionUI } from 'components/SubjectFormQuestions/types';
import { IProject } from 'api/models/responses/projects/projectDetails';
import { IChangeSequencePayload } from 'hooks/useDragAndDrop';

import {
	IProjectsState,
	IProjectCreationFormState,
	IUpdateFormQuestionsParams,
	ISubjectFormQuestionErrorPayload,
} from './types';

const initialState: IProjectsState = {
	organizationKey: 0,
	createdProject: null,
	projectCreationForm: {
		saleType1: null,
		saleType2: null,
		saleType3: null,
		priceListsSale1: [],
		priceListsSale2: [],
		priceListsSale3: [],
		leadCampaignsList: [],
		buyerCampaignsList: [],
		subjectFormQuestions: [],
		prospectCampaignsList: [],
	},
};

const projectsSlice = createSlice({
	name: 'projects',
	initialState,
	reducers: {
		setOrganizationKey: (state, { payload }: PayloadAction<number>) => {
			state.organizationKey = payload;
		},

		setCreatedProject: (state, { payload }: PayloadAction<IProject>) => {
			state.createdProject = payload;
		},

		setProjectCreationForm: (
			state,
			{ payload }: PayloadAction<IProjectCreationFormState>
		) => {
			state.projectCreationForm = payload;
		},

		setSubjectFormQuestions: (
			state,
			{ payload }: PayloadAction<ISubjectFormQuestionUI[]>
		) => {
			state.projectCreationForm.subjectFormQuestions = payload;
		},

		createSubjectFormQuestion: (
			state,
			{ payload }: PayloadAction<ISubjectFormQuestionUI>
		) => {
			state.projectCreationForm.subjectFormQuestions.push(payload);
		},

		updateSubjectFormQuestion: (
			{ projectCreationForm },
			{ payload }: PayloadAction<IUpdateFormQuestionsParams>
		) => {
			const { fieldKey, localKey, value, validate, validationMessage } =
				payload;

			const updatedSubjectFormQuestions =
				projectCreationForm.subjectFormQuestions.map((subjectFormQuestion) => {
					if (subjectFormQuestion.localKey === localKey) {
						const { errors } = subjectFormQuestion;

						const updatedError = {
							...errors,
							[fieldKey]: validate
								? validationMessage ?? ''
								: errors[fieldKey] || '',
						};

						return {
							...subjectFormQuestion,
							[fieldKey]: value,
							errors: updatedError,
						};
					}

					return subjectFormQuestion;
				});

			if (validate) {
				const validatedSubjectFormQuestions =
					validateForUniquenessSubjectFormQuestions(
						updatedSubjectFormQuestions
					);

				projectCreationForm.subjectFormQuestions =
					validatedSubjectFormQuestions;

				return;
			}

			projectCreationForm.subjectFormQuestions = updatedSubjectFormQuestions;
		},

		deleteSubjectFormQuestion: (
			{ projectCreationForm },
			{ payload }: PayloadAction<string>
		) => {
			const filteredSubjectFormQuestions =
				projectCreationForm.subjectFormQuestions.filter(
					({ localKey }) => localKey !== payload
				);

			const validatedSubjectFormQuestions =
				validateForUniquenessSubjectFormQuestions(filteredSubjectFormQuestions);

			projectCreationForm.subjectFormQuestions = validatedSubjectFormQuestions;
		},

		setSubjectFormQuestionFiledError: (
			{ projectCreationForm },
			{ payload }: PayloadAction<ISubjectFormQuestionErrorPayload>
		) => {
			const searchedSubjectFormQuestion =
				projectCreationForm.subjectFormQuestions.find(
					({ localKey }) => payload.localKey === localKey
				);

			if (!searchedSubjectFormQuestion) return;

			searchedSubjectFormQuestion.errors[payload.fieldKey] = payload.message;
		},

		changeSequence: (
			{ projectCreationForm },
			{ payload }: PayloadAction<IChangeSequencePayload>
		) => {
			const reorderedSubjectFormQuestions =
				changeEntitiesSequence<ISubjectFormQuestionUI>({
					...payload,
					fieldKey: 'localKey',
					entities: projectCreationForm.subjectFormQuestions,
				});

			projectCreationForm.subjectFormQuestions = reorderedSubjectFormQuestions;
		},

		validateSubjectFromQuestions: ({ projectCreationForm }) => {
			const validatedSubjectFormQuestions =
				validateForUniquenessSubjectFormQuestions(
					projectCreationForm.subjectFormQuestions
				);

			validatedSubjectFormQuestions.forEach(
				({ errors, formQuestion, dataFieldType, options }) => {
					const dataFiledTypeMessage = validateDataFieldType(dataFieldType);
					const formQuestionMessage = validateFormQuestion(formQuestion);
					const optionsMessage = validateOptions(options);

					if (dataFiledTypeMessage) {
						errors.dataFieldType = dataFiledTypeMessage;
					}

					if (formQuestionMessage) {
						errors.formQuestion = formQuestionMessage;
					}

					if (optionsMessage) {
						errors.options = optionsMessage;
					}
				}
			);

			projectCreationForm.subjectFormQuestions = validatedSubjectFormQuestions;
		},
	},
});

export const projectsReducer = projectsSlice.reducer;
export const {
	changeSequence,
	setCreatedProject,
	setOrganizationKey,
	setProjectCreationForm,
	setSubjectFormQuestions,
	createSubjectFormQuestion,
	updateSubjectFormQuestion,
	deleteSubjectFormQuestion,
	validateSubjectFromQuestions,
	setSubjectFormQuestionFiledError,
} = projectsSlice.actions;
