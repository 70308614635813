import { PaginatePromotionsParams } from 'api/models/requests/promotions/paginatePromotionsParams';
import { IPromotionDetails } from 'api/models/responses/promotions/promotionDetails';
import { ICreatePromotion } from 'api/models/requests/promotions/createPromotion';
import { PromotionsResponse } from 'api/models/responses/promotions/promotion';

import { promotionsUrls } from 'constants/apiUrls/promotionsUrls';
import { api } from 'config/api';

class PromotionsService {
	async getPromotions({
		skip,
		take,
		orderBy,
		orderDirection,
	}: PaginatePromotionsParams): Promise<PromotionsResponse> {
		const { data } = await api.get<PromotionsResponse>(
			promotionsUrls.promotionsUrl,
			{
				params: {
					Skip: skip,
					Take: take,
					OrderBy: orderBy,
					OrderDirection: orderDirection,
				},
			}
		);

		return data;
	}

	async getPromotionDetails(promotionKey: number): Promise<IPromotionDetails> {
		const { data } = await api.get<IPromotionDetails>(
			promotionsUrls.promotionsUrlByKey(promotionKey)
		);

		return data;
	}

	async createPromotion(body: ICreatePromotion): Promise<IPromotionDetails> {
		const { data } = await api.post<IPromotionDetails>(
			promotionsUrls.promotionsUrl,
			body
		);

		return data;
	}

	async updatePromotion(
		promotionKey: number,
		body: ICreatePromotion
	): Promise<IPromotionDetails> {
		const { data } = await api.put<IPromotionDetails>(
			promotionsUrls.promotionsUrlByKey(promotionKey),
			body
		);

		return data;
	}

	async deletePromotion(promotionKey: number): Promise<void> {
		await api.delete<string>(promotionsUrls.promotionsUrlByKey(promotionKey));
	}
}

export default new PromotionsService();
