import { useNavigate } from 'react-router-dom';
import { FC, useEffect } from 'react';

import { ImagoPriceListTabs } from 'constants/priceLists/tabs/imagoPriceListTabs';
import { ROUTES } from 'constants/ROUTES';

import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useAppDispatch } from 'hooks/redux/useAppDispatch';

import { ImagoPoseOptionTypes } from 'api/models/responses/priceLists/imago/priceListOptions/poseOptionTypes';
import { PriceListControls } from 'pages/PriceLists/components/PriceListControls';
import {
	deletePoseOptionSection,
	emptyImagoBackgroundsSelector,
} from 'store/priceLists/imago/priceListPoseOptions';

import { PackageOptionsContainer } from './components/PackageOptionsContainer';
import { PoseOptionsContainer } from './components/PoseOptionsContainer';
import { IPriceListOptionsProps } from '../..';

export const PriceListOptions: FC<IPriceListOptionsProps> = ({
	priceListKey,
	setActiveTab,
	isPriceListPending,
}) => {
	const emptyImagoBackgrounds = useAppSelector(emptyImagoBackgroundsSelector);

	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const handleBack = () => {
		navigate(ROUTES.PRICE_LISTS.PRICE_LISTS);
	};

	const handleNext = () => {
		setActiveTab(ImagoPriceListTabs.Packages);
	};

	useEffect(() => {
		if (emptyImagoBackgrounds) {
			dispatch(deletePoseOptionSection(ImagoPoseOptionTypes.Backgrounds));
		}
	}, []);

	return (
		<>
			<PoseOptionsContainer
				priceListKey={priceListKey}
				isPriceListPending={isPriceListPending}
			/>
			<PackageOptionsContainer />
			<PriceListControls handleBack={handleBack} handleNext={handleNext} />
		</>
	);
};
