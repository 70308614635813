import { FC, useRef, useState } from 'react';

import { CloudPhotoType } from 'api/models/responses/photos/cloudPhotoType';
import PhotoService from 'api/services/PhotoService';

import { photosBlobStorageHighResUrl } from 'constants/images/blobUrls';
import { PhotosActionMenu } from 'constants/photos/photosActionMenu';

import { ConfirmDelete } from 'components/Modal/components/ConfirmDelete';
import { NoItemsFound } from 'components/NoItemsFound';
import { TableLoader } from 'components/TableLoader';
import { Modal, ModalRef } from 'components/Modal';
import { Loader } from 'components/Loader';

import { getPhotoBlobUrl } from 'utils/photos/getPhotoBlobUrl';
import { prepareSasUrl } from 'utils/photos/prepareSasUrl';

import { IPhotoUI, IPhotosProps, IUpdatePhotoValues } from './types';
import { UpdatePhotoForm } from './components/UpdatePhotoForm';
import { PhotosList } from './components/PhotosList';

export const Photos: FC<IPhotosProps> = ({
	orderBy,
	photosUI,
	isPending,
	projectKey,
	setPhotosUI,
	setSortParams,
	orderDirection,
	setIsDeletedPhotos,
	getPaginatedPhotos,
	isPaginationPending,
}) => {
	const [editPhotoKey, setEditPhotoKey] = useState<string | null>(null);
	const [photoName, setPhotoName] = useState<string | null>(null);
	const [editPhotoInitial, setEditPhotoInitial] =
		useState<IUpdatePhotoValues | null>(null);

	const downloadLinkRef = useRef<HTMLAnchorElement>(null);
	const confirmDeleteModalRef = useRef<ModalRef>(null);
	const editPhotoModalRef = useRef<ModalRef>(null);

	const showEditPhotoModal = () => {
		editPhotoModalRef?.current?.open();
	};

	const hideEditPhotoModal = () => {
		editPhotoModalRef?.current?.close();
	};

	const showConfirmDeleteModal = () => {
		confirmDeleteModalRef?.current?.open();
	};

	const hideConfirmDeleteModal = () => {
		confirmDeleteModalRef?.current?.close();
	};

	const handleDelete = async () => {
		if (!editPhotoKey) return;

		const updatedPhotos = photosUI.results.filter(
			(photo) => photo.id !== editPhotoKey
		);

		setPhotosUI({ ...photosUI, results: updatedPhotos });
		hideConfirmDeleteModal();

		try {
			await PhotoService.deletePhoto(projectKey, editPhotoKey);

			setIsDeletedPhotos(true);
		} catch (error) {
			console.log(error);
		}

		setEditPhotoKey(null);
	};

	const handleDownload = async (id: string, fileName: string) => {
		try {
			const downloadLink = downloadLinkRef.current;

			const mainSasUrl = await PhotoService.getImageSasUrl(
				id,
				CloudPhotoType.Main
			);

			if (!downloadLink) return;

			const mainUrl = prepareSasUrl(CloudPhotoType.Main, mainSasUrl);

			if (!mainUrl) return;

			const blobUrl = getPhotoBlobUrl(photosBlobStorageHighResUrl, mainUrl);

			downloadLink.href = blobUrl;
			downloadLink.download = fileName;
			downloadLink.click();
		} catch (error) {
			console.log(error);
		}
	};

	const handleActionMenuItemClick = (
		{
			id,
			password,
			fileName,
			imageType,
			cloudImageId,
			hiResDownload,
		}: IPhotoUI,
		menuItem: PhotosActionMenu
	) => {
		switch (menuItem) {
			case PhotosActionMenu.EditPhoto: {
				setEditPhotoKey(id);
				setEditPhotoInitial({
					password,
					imageType,
					freeDownload: hiResDownload,
				});
				showEditPhotoModal();
				break;
			}

			case PhotosActionMenu.ViewAsConsumer:
				return;

			case PhotosActionMenu.DownloadFile:
				void handleDownload(cloudImageId, fileName);
				break;

			case PhotosActionMenu.DeletePermanently:
				setEditPhotoKey(id);
				setPhotoName(fileName);
				showConfirmDeleteModal();
				break;

			default:
				break;
		}
	};

	if (isPending) {
		return <Loader />;
	}

	if (!photosUI.results.length) {
		return <NoItemsFound title="images" />;
	}

	return (
		<>
			<PhotosList
				orderBy={orderBy}
				data={photosUI.results}
				setSortParams={setSortParams}
				orderDirection={orderDirection}
				getPaginatedPhotos={getPaginatedPhotos}
				handleActionMenuItemClick={handleActionMenuItemClick}
			/>
			{isPaginationPending && <TableLoader />}
			<a download ref={downloadLinkRef} style={{ display: 'none' }} />
			<Modal
				ref={editPhotoModalRef}
				title="Update Image Details"
				subtitle="Update the details for this image."
			>
				<UpdatePhotoForm
					photosUI={photosUI}
					projectKey={projectKey}
					photoKey={editPhotoKey}
					setPhotosUI={setPhotosUI}
					editPhotoInitial={editPhotoInitial}
					hideEditPhotoModal={hideEditPhotoModal}
				/>
			</Modal>
			<Modal
				title="Delete Photo"
				ref={confirmDeleteModalRef}
				subtitle={`Are you sure you would like to delete ${photoName || ''}?`}
			>
				<ConfirmDelete
					handlePermanentlyDelete={handleDelete}
					hideConfirmDeleteModal={hideConfirmDeleteModal}
				/>
			</Modal>
		</>
	);
};
