import { Dispatch } from '@reduxjs/toolkit';

import { PaginatePromotionsParams } from 'api/models/requests/promotions/paginatePromotionsParams';
import PromotionsService from 'api/services/PromotionsService';

import {
	setPromotions,
	deletePromotion,
	setPromotionsPending,
	setPaginatedPromotions,
	setPromotionsPaginationPending,
} from './promotionsSlice';

export const getPromotionsAsync =
	(searchPromotionsParams: PaginatePromotionsParams, isPaginated?: boolean) =>
	async (dispatch: Dispatch) => {
		if (!isPaginated) {
			dispatch(setPromotionsPending(true));
		} else {
			dispatch(setPromotionsPaginationPending(true));
		}

		try {
			const data = await PromotionsService.getPromotions(
				searchPromotionsParams
			);

			if (!isPaginated) {
				dispatch(setPromotions(data));
			} else {
				dispatch(setPaginatedPromotions(data));
			}
		} catch (error) {
			console.log(error);
		}

		dispatch(setPromotionsPending(false));
		dispatch(setPromotionsPaginationPending(false));
	};

export const deletePromotionAsync =
	(promotionKey: number) => async (dispatch: Dispatch) => {
		try {
			dispatch(deletePromotion(promotionKey));

			await PromotionsService.deletePromotion(promotionKey);
		} catch (error) {
			console.log(error);
		}
	};
