import { useCallback, useEffect, useRef, useState } from 'react';
import { useToggleSection } from 'hooks/useToggleSection';

import { IBankAccountResponse } from 'api/models/responses/studioBilling/bankAccount';
import StudioBillingService from 'api/services/StudioBillingService';

import { Button } from 'components/FormControls/Button';
import { InputGrid } from 'components/FormControls/InputGrid';
import { ToggleSection } from 'components/ToggleSection';
import { Modal, ModalRef } from 'components/Modal';

import { UpdateBankAccount } from 'pages/Accounting/modals/UpdateBankAccount';

export const BankInformation = () => {
	const { isOpen, handleToggle } = useToggleSection(true);
	const [bankInfo, setBankInfo] = useState<IBankAccountResponse | null>(null);
	const [showRoutingNum, setShowRoutingNum] = useState(false);
	const [showAccNum, setShowAccNum] = useState(false);

	const handleChangeRoutingNumVisibility = () => {
		setShowRoutingNum((prev) => !prev);
	};

	const handleChangeAccNumVisibility = () => {
		setShowAccNum((prev) => !prev);
	};

	const getBankInfo = useCallback(async () => {
		try {
			const data = await StudioBillingService.getBankACHInfo();
			setBankInfo(data);
		} catch (error) {
			console.log(error);
		}
	}, []);

	useEffect(() => {
		void getBankInfo();
	}, [getBankInfo]);

	const updateBankAccRef = useRef<ModalRef>(null);

	const showUpdateBankAccModal = () => {
		updateBankAccRef?.current?.open();
	};

	const hideUpdateBankAccModal = () => {
		updateBankAccRef?.current?.close();
	};

	return (
		<div className="accing-toggle-section">
			<ToggleSection
				title="Bank ACH Information"
				isOpen={isOpen}
				className="accing-toggle-header"
				handleToggle={handleToggle}
			>
				<div className="accing-wrapper">
					<span className="accing-title">
						This is the account we will payout your profits to.
					</span>
					<div className="accing-list-wrapper">
						<ul className="accing-field-list">
							<li className="accing-field-item">
								<InputGrid
									row
									id="routingNumber"
									value={bankInfo?.bankRoutingNumber}
									type={showRoutingNum ? 'text' : 'password'}
									label="Routing #"
									readOnly
									required={false}
									placeholder="**********"
									handleChange={() => {}}
								/>
								<span
									className="acc-toggle-btn"
									onClick={handleChangeRoutingNumVisibility}
								>
									{showRoutingNum ? 'Hide' : 'Show'}
								</span>
							</li>
							<li className="accing-field-item">
								<InputGrid
									row
									id="accountNumber"
									value={bankInfo?.bankAccountNumberLastFour}
									type={showAccNum ? 'text' : 'password'}
									label="Account #"
									readOnly
									required={false}
									placeholder="**********"
									handleChange={() => {}}
								/>
								<span
									className="acc-toggle-btn"
									onClick={handleChangeAccNumVisibility}
								>
									{showAccNum ? 'Hide' : 'Show'}
								</span>
							</li>
						</ul>
						<Button
							className="btn-secondary accing-btn"
							value="Update Bank Account"
							handleClick={showUpdateBankAccModal}
						/>
					</div>
				</div>
			</ToggleSection>
			<Modal
				title="Update Bank Account ACH Details"
				subtitle="This account will be used for all profit payouts for this studio."
				ref={updateBankAccRef}
			>
				<UpdateBankAccount closeModal={hideUpdateBankAccModal} />
			</Modal>
		</div>
	);
};
