import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ProjectTypeFilterSelectOption } from 'types/projects/selectOptions/projectTypeFilterSelectOption';
import { JourneyFilterSelectOption } from 'types/projects/selectOptions/journeyFilterSelectOption';

import { getInitialPaginationResponse } from 'constants/general/pagination/getInitialPaginationResponse';
import { GENERAL_SELECT_OPTION } from 'constants/projects/selectOptions/generalFilterSelectOption';

import { IBasePreset } from 'api/models/responses/presets/presets';

import {
	IJourneyPresetsState,
	ICreateJourneyPresetValues,
	PaginatedJourneyPresets,
} from './types';

const initialPaginatedJourneyPresets =
	getInitialPaginationResponse<IBasePreset>();

const initialState: IJourneyPresetsState = {
	selectedJourneyPreset: null,
	copiedJourneyPresetKey: null,
	isJourneyPresetsPending: false,
	createJourneyPresetValues: null,
	filterByJourney: GENERAL_SELECT_OPTION,
	isPaginatedJourneyPresetsPending: false,
	filterByProjectType: GENERAL_SELECT_OPTION,
	journeyPresets: initialPaginatedJourneyPresets,
};

const journeyPresetsSlice = createSlice({
	name: 'journeyPresets',
	initialState,
	reducers: {
		setJourneyPresets: (
			state,
			{ payload }: PayloadAction<PaginatedJourneyPresets>
		) => {
			state.journeyPresets = payload;
		},

		setPaginatedJourneyPresets: (
			state,
			{ payload }: PayloadAction<PaginatedJourneyPresets>
		) => {
			state.journeyPresets.results.push(...payload.results);
			state.journeyPresets.totalCount = payload.totalCount;
		},

		setIsJourneyPresetsPending: (
			state,
			{ payload }: PayloadAction<boolean>
		) => {
			state.isJourneyPresetsPending = payload;
		},

		setIsPaginatedJourneyPresetsPending: (
			state,
			{ payload }: PayloadAction<boolean>
		) => {
			state.isPaginatedJourneyPresetsPending = payload;
		},

		setSelectedJourneyPreset: (
			state,
			{ payload }: PayloadAction<IBasePreset | null>
		) => {
			state.selectedJourneyPreset = payload;
		},

		setFilterByJourney: (
			state,
			{ payload }: PayloadAction<JourneyFilterSelectOption>
		) => {
			state.filterByJourney = payload;
		},

		setFilterByProjectType: (
			state,
			{ payload }: PayloadAction<ProjectTypeFilterSelectOption>
		) => {
			state.filterByProjectType = payload;
		},

		setCopiedJourneyPresetKey: (
			state,
			{ payload }: PayloadAction<number | null>
		) => {
			state.copiedJourneyPresetKey = payload;
		},

		setCreateJourneyPresetValues: (
			state,
			{ payload }: PayloadAction<ICreateJourneyPresetValues>
		) => {
			state.createJourneyPresetValues = payload;
		},

		deleteJourneyPreset: (state, { payload }: PayloadAction<number>) => {
			state.journeyPresets.results = state.journeyPresets.results.filter(
				({ presetKey }) => presetKey !== payload
			);
		},
	},
});

export const journeyPresetsReducer = journeyPresetsSlice.reducer;
export const {
	setJourneyPresets,
	setFilterByJourney,
	deleteJourneyPreset,
	setFilterByProjectType,
	setSelectedJourneyPreset,
	setCopiedJourneyPresetKey,
	setIsJourneyPresetsPending,
	setPaginatedJourneyPresets,
	setCreateJourneyPresetValues,
	setIsPaginatedJourneyPresetsPending,
} = journeyPresetsSlice.actions;
