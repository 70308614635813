import { FC, useCallback, useEffect, useState } from 'react';

import { ICreateAdditionalChargeOption } from 'api/models/requests/priceLists/studio/priceListOptions/createAdditionalChargeOption';
import { ChargeOptionTypes } from 'api/models/responses/priceLists/studio/priceListOptions/additionalChargeOption';
import AdditionalChargeOptionsService from 'api/services/PriceListsService/studio/AdditionalChargeOptionsService';
import { IErrorResponse } from 'api/models/responses/general/errorResponse';
import { UnitTypes } from 'api/models/responses/general/unitTypes';
import { IPatchBody } from 'api/models/requests/general/patchBody';

import { useCreatePriceListOptionBtn } from 'pages/PriceLists/hooks/useCreatePriceListOptionBtn';
import {
	IAdditionalChargeOptionUI,
	IDeleteAdditionalChargeOption,
	IUpdateAdditionalChargeOption,
	IClearAdditionalChargeOptionError,
} from 'pages/PriceLists/types/studio/priceListOptions/additionalChargeOptions';
import {
	PriceTable,
	IHeaderConfig,
} from 'pages/PriceLists/components/PriceTable';

import { validateDaysAmount } from 'utils/validations/priceLists/studio/validateDaysAmount';
import { validatePercentValue } from 'utils/validations/priceLists/validatePercentValue';
import { validateRetailValue } from 'utils/validations/priceLists/validateRetailValue';
import { validateOptionName } from 'utils/validations/priceLists/validateOptionName';
import { changeEntitiesSequence } from 'utils/dragAndDrop/changeEntitiesSequence';

import { IChangeSequencePayload } from 'hooks/useDragAndDrop';
import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useAppDispatch } from 'hooks/redux/useAppDispatch';

import { initialAdditionalChargeOptionErrors } from 'constants/priceLists/studio/initialAdditionalChargeOptionErrors';
import { Button } from 'components/FormControls/Button';
import {
	setAdditionalChargeOptions,
	additionalChargeOptionsSelector,
} from 'store/priceLists/studio/priceListBaseOptions';

import { AdditionalChargeOption } from './AdditionalChargeOption';
import { SaleTaxChargeOption } from './SaleTaxChargeOption';
import { IPriceListOptionsProps } from '../../../..';
import { OptionsTitle } from '../OptionsTitle';

const additionalChargeOptionsHeaders: IHeaderConfig[] = [
	{ name: 'Name', required: true, left: true },
	{ name: 'Amount', required: true, left: true },
	{ name: 'Option', required: true, left: true },
];

export interface IAdditionalChargeOptionsProps
	extends Pick<IPriceListOptionsProps, 'updatePriceListStudio'> {
	priceListKey?: number;
	salesTaxPercent?: number;
	isChargeSalesTax?: boolean;
}

export const AdditionalChargeOptions: FC<IAdditionalChargeOptionsProps> = ({
	priceListKey,
	salesTaxPercent,
	isChargeSalesTax,
	updatePriceListStudio,
}) => {
	const [isReordered, setIsReordered] = useState(false);

	const additionalChargeOptionsUI = useAppSelector(
		additionalChargeOptionsSelector
	);

	const dispatch = useAppDispatch();

	const lastAdditionalChargeOptionUI =
		additionalChargeOptionsUI[additionalChargeOptionsUI.length - 1];

	const handleAddAdditionalChargeOption = () => {
		if (!priceListKey) return;

		const initialSequence =
			additionalChargeOptionsUI.length &&
			lastAdditionalChargeOptionUI.sequence + 1;

		const newAdditionalChargeOption: IAdditionalChargeOptionUI = {
			name: '',
			amount: 0,
			daysAmount: 0,
			sequence: initialSequence,
			chargeUnitType: UnitTypes.Dollar,
			priceListStudioFulfilledKey: priceListKey,
			chargeOptionType: ChargeOptionTypes.Always,
			errors: initialAdditionalChargeOptionErrors,
		};

		dispatch(
			setAdditionalChargeOptions([
				...additionalChargeOptionsUI,
				newAdditionalChargeOption,
			])
		);
	};

	const handleSaveAdditionalChargeOption = async () => {
		if (!priceListKey) return;

		const {
			name,
			amount,
			sequence,
			daysAmount,
			chargeUnitType,
			chargeOptionType,
		} = lastAdditionalChargeOptionUI;

		const slicedAdditionalChargeOptionsUI = additionalChargeOptionsUI.slice(
			0,
			-1
		);

		const daysAmountValidationMessage = validateDaysAmount(daysAmount);
		const nameValidationMessage = validateOptionName(name);
		const amountValidationMessage =
			chargeUnitType === UnitTypes.Dollar
				? validateRetailValue(amount)
				: validatePercentValue(amount);

		const isValid =
			!nameValidationMessage &&
			!amountValidationMessage &&
			!daysAmountValidationMessage;

		if (!isValid) {
			const updatedAdditionalChargeOptionUI: IAdditionalChargeOptionUI = {
				...lastAdditionalChargeOptionUI,
				errors: {
					...initialAdditionalChargeOptionErrors,
					name: nameValidationMessage,
					amount: amountValidationMessage,
					daysAmount: daysAmountValidationMessage,
				},
			};

			dispatch(
				setAdditionalChargeOptions([
					...slicedAdditionalChargeOptionsUI,
					updatedAdditionalChargeOptionUI,
				])
			);

			return;
		}

		try {
			const body: ICreateAdditionalChargeOption = {
				name,
				amount,
				sequence,
				daysAmount,
				chargeUnitType,
				chargeOptionType,
			};

			const data =
				await AdditionalChargeOptionsService.createAdditionalChargeOption(
					priceListKey,
					body
				);

			const updatedAdditionalChargeOptionUI: IAdditionalChargeOptionUI = {
				...data,
				errors: initialAdditionalChargeOptionErrors,
			};

			dispatch(
				setAdditionalChargeOptions([
					...slicedAdditionalChargeOptionsUI,
					updatedAdditionalChargeOptionUI,
				])
			);
		} catch (error) {
			const { errors } = error as IErrorResponse;

			const updatedAdditionalChargeOptionUI: IAdditionalChargeOptionUI = {
				...lastAdditionalChargeOptionUI,
				errors: {
					...initialAdditionalChargeOptionErrors,
					...errors,
				},
			};

			dispatch(
				setAdditionalChargeOptions([
					...slicedAdditionalChargeOptionsUI,
					updatedAdditionalChargeOptionUI,
				])
			);
		}
	};

	const updateAdditionalChargeOption = async ({
		value,
		fieldKey,
		sequence,
		validationMessage,
		additionalChargeOptionKey,
	}: IUpdateAdditionalChargeOption) => {
		if (!additionalChargeOptionKey || validationMessage) {
			const updatedAdditionalChargeOptionUI = additionalChargeOptionsUI.map(
				(additionalChargeOptionUI) => {
					if (additionalChargeOptionUI.sequence === sequence) {
						return {
							...additionalChargeOptionUI,
							[fieldKey]: value,
							errors: {
								...additionalChargeOptionUI.errors,
								[fieldKey]: validationMessage,
							},
						};
					}

					return additionalChargeOptionUI;
				}
			);

			dispatch(setAdditionalChargeOptions(updatedAdditionalChargeOptionUI));
			return;
		}

		if (!additionalChargeOptionKey || !priceListKey) return;

		try {
			const updatedField: IPatchBody = {
				value,
				op: 'replace',
				path: fieldKey,
			};

			const body: IPatchBody[] = [updatedField];

			const data =
				await AdditionalChargeOptionsService.updateAdditionalChargeOption(
					priceListKey,
					additionalChargeOptionKey,
					body
				);

			const updatedAdditionalChargeOptionUI = additionalChargeOptionsUI.map(
				(additionalChargeOptionUI) =>
					additionalChargeOptionUI.priceListStudioFulfilledAdditionalChargeOptionKey ===
					additionalChargeOptionKey
						? {
								...data,
								errors: initialAdditionalChargeOptionErrors,
						  }
						: additionalChargeOptionUI
			);

			dispatch(setAdditionalChargeOptions(updatedAdditionalChargeOptionUI));
		} catch (error) {
			const { errors } = error as IErrorResponse;

			const updatedAdditionalChargeOptionUI = additionalChargeOptionsUI.map(
				(additionalChargeOptionUI) =>
					additionalChargeOptionUI.priceListStudioFulfilledAdditionalChargeOptionKey ===
					additionalChargeOptionKey
						? {
								...additionalChargeOptionUI,
								errors: {
									...initialAdditionalChargeOptionErrors,
									[fieldKey]: errors[fieldKey],
								},
						  }
						: additionalChargeOptionUI
			);

			dispatch(setAdditionalChargeOptions(updatedAdditionalChargeOptionUI));
		}
	};

	const deleteAdditionalChargeOption = async ({
		sequence,
		additionalChargeOptionKey,
	}: IDeleteAdditionalChargeOption) => {
		if (!priceListKey) return;

		const updatedAdditionalChargeOptionsUI = additionalChargeOptionsUI.filter(
			(additionalChargeOptionUI) =>
				additionalChargeOptionUI.sequence !== sequence
		);

		dispatch(setAdditionalChargeOptions(updatedAdditionalChargeOptionsUI));

		if (!additionalChargeOptionKey) return;

		try {
			await AdditionalChargeOptionsService.deleteAdditionalChargeOption(
				priceListKey,
				additionalChargeOptionKey
			);
		} catch (error) {
			console.log(error);
		}
	};

	const clearAdditionalChargeOptionError = ({
		sequence,
		fieldKey,
	}: IClearAdditionalChargeOptionError) => {
		const updatedAdditionalChargeOptionsUI = additionalChargeOptionsUI.map(
			(additionalChargeOptionUI) =>
				additionalChargeOptionUI.sequence === sequence
					? {
							...additionalChargeOptionUI,
							errors: { ...additionalChargeOptionUI.errors, [fieldKey]: '' },
					  }
					: additionalChargeOptionUI
		);

		dispatch(setAdditionalChargeOptions(updatedAdditionalChargeOptionsUI));
	};

	const changeAdditionalChargeOptionsSequence = (
		payload: IChangeSequencePayload
	) => {
		const reorderedAdditionalChargeOptionsUI = changeEntitiesSequence({
			...payload,
			entities: additionalChargeOptionsUI,
			fieldKey: 'priceListStudioFulfilledAdditionalChargeOptionKey',
		});

		dispatch(setAdditionalChargeOptions(reorderedAdditionalChargeOptionsUI));
		setIsReordered(true);
	};

	const reorderAdditionalChargeOptions = useCallback(async () => {
		if (!priceListKey || !isReordered) return;

		const body = additionalChargeOptionsUI.reduce<Record<number, number>>(
			(acc, { priceListStudioFulfilledAdditionalChargeOptionKey, sequence }) =>
				priceListStudioFulfilledAdditionalChargeOptionKey
					? {
							...acc,
							[priceListStudioFulfilledAdditionalChargeOptionKey]: sequence,
					  }
					: acc,
			{}
		);

		try {
			await AdditionalChargeOptionsService.reorderAdditionalChargeOptions(
				priceListKey,
				body
			);
		} catch (error) {
			console.log(error);
		}

		setIsReordered(false);
	}, [isReordered, priceListKey]);

	useEffect(() => {
		void reorderAdditionalChargeOptions();
	}, [reorderAdditionalChargeOptions]);

	const AdditionalChargeOptionsList = additionalChargeOptionsUI.map(
		(additionalChargeOptionUI) => (
			<AdditionalChargeOption
				changeAdditionalChargeOptionsSequence={
					changeAdditionalChargeOptionsSequence
				}
				additionalChargeOptionUI={additionalChargeOptionUI}
				updateAdditionalChargeOption={updateAdditionalChargeOption}
				deleteAdditionalChargeOption={deleteAdditionalChargeOption}
				clearAdditionalChargeOptionError={clearAdditionalChargeOptionError}
				key={
					additionalChargeOptionUI.priceListStudioFulfilledAdditionalChargeOptionKey ||
					additionalChargeOptionUI.sequence
				}
			/>
		)
	);

	const showSaveBtn =
		!!additionalChargeOptionsUI.length &&
		!lastAdditionalChargeOptionUI?.priceListStudioFulfilledAdditionalChargeOptionKey;

	const { btnValue, btnClassName, btnClickHandler } =
		useCreatePriceListOptionBtn({
			showSaveBtn,
			optionName: 'Additional Charge Option',
			handleAdd: handleAddAdditionalChargeOption,
			handleSave: handleSaveAdditionalChargeOption,
		});

	return (
		<div className="price-container">
			<OptionsTitle title="Additional Charge Options" />
			<PriceTable
				headers={additionalChargeOptionsHeaders}
				className="price-table-options"
			>
				<SaleTaxChargeOption
					salesTaxPercent={salesTaxPercent}
					isChargeSalesTax={isChargeSalesTax}
					updatePriceListStudio={updatePriceListStudio}
				/>
				{AdditionalChargeOptionsList}
			</PriceTable>
			<Button
				value={btnValue}
				handleClick={btnClickHandler}
				className={`price-add-back ${btnClassName}`}
			/>
		</div>
	);
};
