import { Dispatch } from '@reduxjs/toolkit';

import { ISearchPriceLists } from 'api/models/requests/priceLists/searchPriceLists';
import PriceListsService from 'api/services/PriceListsService';

import {
	setPriceLists,
	setIsPriceListsPending,
	setPaginatedPriceLists,
	setIsPaginatedPriceListsPending,
} from './priceListsSlice';

export const searchPriceListsAsync =
	(searchParams: ISearchPriceLists, isPaginated = false) =>
	async (dispatch: Dispatch) => {
		if (!isPaginated) {
			dispatch(setIsPriceListsPending(true));
		} else {
			dispatch(setIsPaginatedPriceListsPending(true));
		}

		try {
			const data = await PriceListsService.searchPriceLists(searchParams);

			if (isPaginated) {
				dispatch(setPaginatedPriceLists(data));
			} else {
				dispatch(setPriceLists(data));
			}
		} catch (error) {
			console.log(error);
		}

		dispatch(setIsPriceListsPending(false));
		dispatch(setIsPaginatedPriceListsPending(false));
	};
