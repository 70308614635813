import { FC } from 'react';
import { Formik, Form } from 'formik';

import StudioBillingService from 'api/services/StudioBillingService';
import { IBankAccountBody } from 'api/models/requests/studioBilling/bankAccount';

import { useAppDispatch } from 'hooks/redux/useAppDispatch';

import { setBankAcc } from 'store/studioBilling';

import { ButtonTypes } from 'types/ui/buttonTypes';

import { Button } from 'components/FormControls/Button';
import { InputGrid } from 'components/FormControls/InputGrid';

interface ICloseModal {
	closeModal: () => void;
}

export const UpdateBankAccount: FC<ICloseModal> = ({ closeModal }) => {
	const initialValues: IBankAccountBody = {
		studioKey: 0,
		bankRoutingNumber: '',
		bankAccountNumber: '',
		bankAccountName: '',
	};

	const dispatch = useAppDispatch();

	const onSubmit = async (bankAccountBody: IBankAccountBody) => {
		try {
			const data = await StudioBillingService.updateBankACHInformation(
				bankAccountBody
			);

			if (!data) return;

			dispatch(setBankAcc(data));
		} catch (error) {
			console.log(error, 'error');
		}
	};

	return (
		<Formik initialValues={initialValues} onSubmit={onSubmit}>
			{({ handleChange, values }) => (
				<Form className="modal-body accing-modal">
					<div className="accing-modal-form">
						<div className="accing-modal-input">
							<InputGrid
								label="Account Holder’s Name"
								placeholder="Placeholder"
								required
								id="bankAccountName"
								type="text"
								value={values.bankAccountName}
								handleChange={handleChange}
							/>
						</div>
						<InputGrid
							label="Routing #"
							placeholder="Placeholder"
							required
							id="bankRoutingNumber"
							type="text"
							value={values.bankRoutingNumber}
							handleChange={handleChange}
						/>
						{/* <InputGrid
							label="Confirm Routing #"
							placeholder="Placeholder"
							required
						/> */}
						<InputGrid
							label="Account #"
							placeholder="Placeholder"
							required
							id="bankAccountNumber"
							type="text"
							value={values.bankAccountNumber}
							handleChange={handleChange}
						/>
						{/* <InputGrid
							label="Confirm Account #"
							placeholder="Placeholder"
							required
						/> */}
					</div>
					<div className="accing-modal-btns">
						<Button
							className="btn-primary"
							value="Update Account On File"
							type={ButtonTypes.SUBMIT}
						/>
						<Button
							className="btn-secondary"
							value="Back"
							handleClick={closeModal}
						/>
					</div>
				</Form>
			)}
		</Formik>
	);
};
