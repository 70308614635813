import { number, object, string } from 'yup';

import {
	requiredFieldMessage,
	oneOfTheFieldsRequiredMessage,
} from 'constants/general/validation/generalMessages';

export const sendTestBroadcastValidationSchema = object().shape(
	{
		projectKey: number().required(requiredFieldMessage),
		sendToEmailAddress: string().when('sendToPhoneNumber', {
			is: (value: string) => !value || !value.length,
			then: () => string().required(oneOfTheFieldsRequiredMessage),
		}),
		sendToPhoneNumber: string().when('sendToEmailAddress', {
			is: (value: string) => !value || !value.length,
			then: () => string().required(oneOfTheFieldsRequiredMessage),
		}),
	},
	[['sendToEmailAddress', 'sendToPhoneNumber']]
);
