import { SingleValue } from 'react-select';
import { useFormikContext } from 'formik';
import { ChangeEvent, FC } from 'react';

import { statesSelectOptions } from 'constants/general/states/statesSelectOptions';
import { VALID_FIELDS_LENGTH } from 'constants/auth/validation';

import { SelectComponent } from 'components/FormControls/Select';
import { InputField } from 'components/InputField';

import { ISelectOption } from 'types/ui/select';
import { States } from 'types/ui/states';

import { validateStringNumber } from 'utils/validations/general/validateStringNumber';

import { Domain } from './Domain';
import { ICreateStudioForm } from '../..';

export const StudioInformation: FC = () => {
	const { values, errors, touched, handleChange, setFieldValue } =
		useFormikContext<ICreateStudioForm>();

	const handleSelectChange = (option: SingleValue<ISelectOption<States>>) => {
		if (!option) return;

		void setFieldValue('state', option.value);
	};

	const handleBlurStudioName = () => {
		const studioName = values.studioName.trim();

		void setFieldValue('studioName', studioName);
	};

	const handleZipChange = (e: ChangeEvent<HTMLInputElement>) => {
		const isValidZip = validateStringNumber(
			e.target.value,
			VALID_FIELDS_LENGTH.ZIP
		);

		if (!isValidZip) return;

		handleChange(e);
	};

	const handlePhoneChange = (e: ChangeEvent<HTMLInputElement>) => {
		const isValidPhone = validateStringNumber(
			e.target.value,
			VALID_FIELDS_LENGTH.PHONE
		);

		if (!isValidPhone) return;

		handleChange(e);
	};

	return (
		<div className="studio-form">
			<h4 className="studio-subtitle">
				This information will be visible to your consumers.
			</h4>
			<InputField
				id="studioName"
				label="Studio Name"
				placeholder="Studio Name"
				value={values.studioName}
				handleChange={handleChange}
				touched={!!touched.studioName}
				error={errors.studioName || ''}
				wrapperClassName="form-container"
				handleBlur={handleBlurStudioName}
			/>
			<Domain
				handleChange={handleChange}
				value={values.networkDomain}
				touched={!!touched.networkDomain}
				error={errors.networkDomain || ''}
			/>
			<InputField
				id="phone"
				label="Phone Number"
				value={values.phone}
				touched={!!touched.phone}
				error={errors.phone || ''}
				placeholder="Phone Number'"
				handleChange={handlePhoneChange}
				wrapperClassName="form-container"
			/>
			<InputField
				id="street"
				label="Address"
				value={values.street}
				placeholder="Address"
				touched={!!touched.street}
				handleChange={handleChange}
				error={errors.street || ''}
				wrapperClassName="form-container"
			/>
			<InputField
				id="city"
				label="City"
				placeholder="City"
				value={values.city}
				touched={!!touched.city}
				error={errors.city || ''}
				handleChange={handleChange}
				wrapperClassName="form-container"
			/>
			<div className="form-col-container">
				<div className="form-col">
					<SelectComponent
						id="state"
						label="State"
						value={values.state}
						touched={!!touched.state}
						error={errors.state || ''}
						onChange={handleSelectChange}
						selectPlaceholder="State - ST"
						selectOptions={statesSelectOptions}
					/>
				</div>
				<InputField
					id="zip"
					label="Zipcode"
					value={values.zip}
					placeholder="Zipcode"
					touched={!!touched.zip}
					error={errors.zip || ''}
					wrapperClassName="form-col"
					handleChange={handleZipChange}
				/>
			</div>
		</div>
	);
};
