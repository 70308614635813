import { FC, useEffect } from 'react';

import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useToastify } from 'hooks/useToastify';

import { SubscriptionNames } from 'constants/subscriptions/subscriptionNames';

import SubscriptionsService from 'api/services/SubscriptionsService';

import {
	setSubscriptions,
	subscriptionsSelector,
	activeSubscriptionKeySelector,
	setActiveSubscriptionKey,
	setSubscriptionLevelKey,
} from 'store/subscriptions';
import { studioKeySelector } from 'store/studio';

import { TableColumn } from 'pages/RegistrationSteps/SelectPlan/components/TableColumn';
import { TableRowNames } from 'pages/RegistrationSteps/SelectPlan/components/TableRowLabels';

import { Button } from 'components/FormControls/Button';
import { IErrorResponse } from 'api/models/responses/general/errorResponse';

interface ICloseModal {
	closeModal: () => void;
}

export const UpgradeYourPlan: FC<ICloseModal> = ({ closeModal }) => {
	const subscriptions = useAppSelector(subscriptionsSelector);
	const studioKey = useAppSelector(studioKeySelector);

	const activeSubscriptionLevelKey = useAppSelector(
		activeSubscriptionKeySelector
	);

	const dispatch = useAppDispatch();

	const { showErrorsToastify } = useToastify();

	const selectSubscription = (subscriptionLevelKey: number) => {
		dispatch(setActiveSubscriptionKey(subscriptionLevelKey));
	};

	const handleChoosePlan = () => {
		SubscriptionsService.setSubscriptionForStudio(
			activeSubscriptionLevelKey,
			studioKey
		)
			.then(() => {
				dispatch(setSubscriptionLevelKey(activeSubscriptionLevelKey));
				closeModal();
			})
			.catch((error: IErrorResponse) => {
				const { type, errors } = error;

				const toastifyError = {
					type,
					errors,
				};

				showErrorsToastify(toastifyError);
			});
	};

	const TableColumnsList = subscriptions.map((subscription) => (
		<TableColumn
			{...subscription}
			key={subscription.subscriptionLevelKey}
			selectSubscription={selectSubscription}
			activeSubscriptionLevelKey={activeSubscriptionLevelKey}
		/>
	));

	useEffect(() => {
		SubscriptionsService.getSubscriptionsList()
			.then((data) => {
				if (!data) return;

				dispatch(setSubscriptions(data));
			})
			.catch((error: IErrorResponse) => {
				const { type, errors } = error;

				const toastifyError = {
					type,
					errors,
				};

				showErrorsToastify(toastifyError);
			});
	}, []);

	useEffect(() => {
		const standardSubscriptionKey = subscriptions.find(
			({ name }) => name === SubscriptionNames.STANDARD
		)?.subscriptionLevelKey;

		if (!standardSubscriptionKey) return;

		dispatch(setActiveSubscriptionKey(standardSubscriptionKey));
	}, [subscriptions]);

	return (
		<div className="plans">
			<div className="panel-plans">
				<TableRowNames />
				{TableColumnsList}
			</div>
			<div>
				<Button
					className="btn-primary"
					value="Upgrade"
					handleClick={handleChoosePlan}
				/>
				<Button
					className="btn-secondary"
					value="Back"
					handleClick={closeModal}
				/>
			</div>
		</div>
	);
};
