import { IPriceListImago } from 'api/models/responses/priceLists/imago/priceListImago';
import { ICreatePriceList } from 'api/models/requests/priceLists/createPriceList';
import { IPatchBody } from 'api/models/requests/general/patchBody';

import { priceListsUrls } from 'constants/apiUrls/priceListsUrls';
import { api } from 'config/api';

class PriceListsImagoService {
	async getPriceListImago(priceListKey: number): Promise<IPriceListImago> {
		const { data } = await api.get<IPriceListImago>(
			priceListsUrls.imagoFulfilled.getPriceListImagoFulfilledUrl(priceListKey)
		);

		return data;
	}

	async createPriceListImago(body: ICreatePriceList): Promise<IPriceListImago> {
		const { data } = await api.post<IPriceListImago>(
			priceListsUrls.imagoFulfilled.createPriceListImagoFulfilled,
			body
		);

		return data;
	}

	async updatePriceListImago(
		priceListKey: number,
		body: IPatchBody[]
	): Promise<IPriceListImago> {
		const { data } = await api.patch<IPriceListImago>(
			priceListsUrls.imagoFulfilled.getPriceListImagoFulfilledUrl(priceListKey),
			body
		);

		return data;
	}

	async deletePriceListImago(priceListKey: number): Promise<string> {
		const { data } = await api.delete<string>(
			priceListsUrls.imagoFulfilled.getPriceListImagoFulfilledUrl(priceListKey)
		);

		return data;
	}
}

export default new PriceListsImagoService();
