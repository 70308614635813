import { useCallback, useEffect, useRef, useState } from 'react';
import { useToggleSection } from 'hooks/useToggleSection';

import StudioBillingService from 'api/services/StudioBillingService';
import { IW9BillingInfoResponse } from 'api/models/responses/studioBilling/w9BillingInfo';

import { Modal, ModalRef } from 'components/Modal';
import { Button } from 'components/FormControls/Button';
import { ToggleSection } from 'components/ToggleSection';

import { UpdateW9Info } from 'pages/Accounting/modals/UpdateW9Info';

export const WInformation = () => {
	const { isOpen, handleToggle } = useToggleSection(true);
	const [wInfo, setWInfo] = useState<IW9BillingInfoResponse | null>(null);

	const getWInfo = useCallback(async () => {
		try {
			const data = await StudioBillingService.getW9Info();
			setWInfo(data);
		} catch (error) {
			console.log(error);
		}
	}, []);

	useEffect(() => {
		void getWInfo();
	}, [getWInfo]);

	const updateWInfoRef = useRef<ModalRef>(null);

	const showWInfoModal = () => {
		updateWInfoRef?.current?.open();
	};

	const hideWInfoModal = () => {
		updateWInfoRef?.current?.close();
	};

	return (
		<div className="accing-toggle-section">
			<ToggleSection
				title="W9 Information"
				isOpen={isOpen}
				className="accing-toggle-header"
				handleToggle={handleToggle}
				header={
					<Button
						className="btn-secondary accing-btn"
						value="Update W9 Info"
						handleClick={showWInfoModal}
					/>
				}
			>
				<div className="accing-wrapper">
					<div className="accing-list-wrapper">
						<ul className="accing-cols">
							<li className="accing-item">
								Name:
								<span>
									{wInfo?.billingFirstName} {wInfo?.billingLastName}
								</span>
							</li>
							<li className="accing-item">
								Business Name: <span>{wInfo?.billingBusinessName}</span>
							</li>
							<li className="accing-item">
								Address:
								<span>
									{wInfo?.billingStreet}, {wInfo?.billingCity},{' '}
									{wInfo?.billingState} {wInfo?.billingPostalCode}
								</span>
							</li>
							<li className="accing-item">
								Phone: <span>{wInfo?.billingPhone}</span>
							</li>
							<li className="accing-item">
								Email: <span>{wInfo?.billingEmail}</span>
							</li>
							<li className="accing-item">
								Entity Type: <span>{wInfo?.billingEntityType}</span>
							</li>
							<li className="accing-item">
								Tax ID Type: <span>SSN</span>
							</li>
							<li className="accing-item">
								Last 4 #s: <span>{wInfo?.tinLastFour}</span>
							</li>
						</ul>
					</div>
				</div>
			</ToggleSection>
			<Modal title="Update Account W-9 Details" ref={updateWInfoRef}>
				<UpdateW9Info closeModal={hideWInfoModal} />
			</Modal>
		</div>
	);
};
