import { FC, ReactElement, useEffect, useState } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { useNavigate } from 'react-router-dom';
import { lazy, object } from 'yup';

import { getProjectFromPrestValidationSchema } from 'pages/Projects/pages/CreateProjectFromPreset/validation';
import { useOrganizationKeyExist } from 'pages/Projects/hooks/useOrganizationKeyExist';

import { ICreateProjectFromPresetBody } from 'api/models/requests/projects/createProjectFromPreset';
import { ProjectJourneys } from 'api/models/requests/projects/projectJourneys';
import { IErrorResponse } from 'api/models/responses/general/errorResponse';
import { IBasePreset } from 'api/models/responses/presets/presets';
import ProjectsService from 'api/services/ProjectsService';

import { journeyTimelines } from 'constants/projects/timeline/journeyTimelines';
import { ROUTES } from 'constants/ROUTES';

import { setCreatedProject, organizationKeySelector } from 'store/projects';
import {
	selectedJourneyPresetSelector,
	isJourneyPresetsPendingSelector,
	setSelectedJourneyPreset,
} from 'store/journeyPresets';

import { getProcessedErrors } from 'utils/errors/getProcessedErrors';
import { prepareDate } from 'utils/dates/setCurrentTimeToDate';

import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useAppSelector } from 'hooks/redux/useAppSelector';

import { ISaleValues } from 'components/Sales/Sale/types';
import { Loader } from 'components/Loader';

import { usePaginatedJourneyPresets } from 'pages/JourneyPresets/hooks/usePaginatedJourneyPresets';

import { CreateProjectFromPresetStep1 } from './CreateProjectFromPresetStep1';
import { CreateProjectFromPresetStep2 } from './CreateProjectFromPresetStep2';
import { Steps } from '../../types';

export interface ICreateProjectFromPresetValues {
	[key: string]:
		| Date
		| null
		| string
		| IBasePreset
		| ISaleValues
		| ProjectJourneys;
	projectUrl: string;
	projectName: string;
	publishDate: Date | null;
	lastPictureDate: Date | null;
	firstPictureDate: Date | null;
	estimatedNumberOfSubjects: string;
}

export const CreateProjectFromPreset: FC = () => {
	const [step, setStep] = useState<Steps>(Steps.STEP1);

	const selectedJourneyPreset = useAppSelector(selectedJourneyPresetSelector);
	const organizationKey = useAppSelector(organizationKeySelector);
	const isJourneyPresetsPending = useAppSelector(
		isJourneyPresetsPendingSelector
	);

	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const { getPaginatedJourneyPresets } = usePaginatedJourneyPresets();

	useOrganizationKeyExist();

	const initialValues: ICreateProjectFromPresetValues = {
		projectUrl: '',
		projectName: '',
		publishDate: null,
		lastPictureDate: null,
		firstPictureDate: null,
		estimatedNumberOfSubjects: '',
	};

	const onSubmit = async (
		values: ICreateProjectFromPresetValues,
		{ setErrors }: FormikHelpers<ICreateProjectFromPresetValues>
	) => {
		const {
			projectUrl,
			projectName,
			publishDate,
			lastPictureDate,
			firstPictureDate,
			estimatedNumberOfSubjects,
		} = values;

		if (!selectedJourneyPreset) return;

		const publishDateParam = publishDate ? prepareDate(publishDate) : '';
		const lastPictureDateParam = lastPictureDate
			? prepareDate(lastPictureDate)
			: '';
		const firstPictureDateParam = firstPictureDate
			? prepareDate(firstPictureDate)
			: '';

		const requestBody: ICreateProjectFromPresetBody = {
			projectUrl,
			projectName,
			organizationKey,
			publishDate: publishDateParam,
			lastPictureDate: lastPictureDateParam,
			firstPictureDate: firstPictureDateParam,
			estimatedNumberOfSubjects: +estimatedNumberOfSubjects,
		};

		try {
			const data = await ProjectsService.createProjectFromPreset(
				selectedJourneyPreset.presetKey,
				requestBody
			);

			dispatch(setCreatedProject(data));

			navigate(`${ROUTES.PROJECTS.PROJECTS}/${data.projectKey}`);
		} catch (error) {
			const { errors } = error as IErrorResponse;

			const processedErrors = getProcessedErrors(values, errors);

			setErrors(processedErrors);
		}
	};

	const validationSchema = lazy(() => {
		const journey = selectedJourneyPreset?.journey;

		if (!journey) return object({});

		const { journeyDate } = journeyTimelines[journey];

		return object(
			getProjectFromPrestValidationSchema(
				journeyDate.datesLabels,
				journeyDate.validateProjectPresetLastPictureDate,
				journeyDate.validateBlankProjectFirstPictureDate
			)
		);
	});

	useEffect(() => {
		dispatch(setSelectedJourneyPreset(null));
	}, []);

	if (isJourneyPresetsPending) return <Loader isFullHeight />;

	const FormSteps: Record<Steps, ReactElement> = {
		[Steps.STEP1]: (
			<CreateProjectFromPresetStep1
				setStep={setStep}
				getPaginatedJourneyPresets={getPaginatedJourneyPresets}
			/>
		),
		[Steps.STEP2]: <CreateProjectFromPresetStep2 setStep={setStep} />,
	};

	return (
		<Formik
			onSubmit={onSubmit}
			enableReinitialize
			initialValues={initialValues}
			validationSchema={validationSchema}
		>
			{FormSteps[step]}
		</Formik>
	);
};
