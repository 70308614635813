import { ChangeEvent, FC, useState } from 'react';
import { SingleValue } from 'react-select';
import { useFormikContext } from 'formik';

import { validateExpirationDate } from 'utils/validations/general/validateExpirationDate';
import { validateStringNumber } from 'utils/validations/general/validateStringNumber';

import { paymentsSelectOptions } from 'constants/payments/paymentsSelectOptions';
import { VALID_FIELDS_LENGTH } from 'constants/auth/validation';

import { SelectComponent } from 'components/FormControls/Select';
import { InputField } from 'components/InputField';

import { ISelectOption } from 'types/ui/select';
import { Payments } from 'types/ui/payments';

import { ICreateStudioForm } from '../..';

export const BillingInformation: FC = () => {
	const [showCardNumber, setShowCardNumber] = useState(false);
	const [showCvv, setShowCvv] = useState(false);

	const { values, errors, touched, handleChange, setFieldValue } =
		useFormikContext<ICreateStudioForm>();

	const handleExpirationChange = (e: ChangeEvent<HTMLInputElement>) => {
		const value = e.currentTarget.value;
		const prevValue = values.expiration;

		const validatedExpirationDate = validateExpirationDate(prevValue, value);

		void setFieldValue('expiration', validatedExpirationDate);
	};

	const handleCardTypeChange = (
		option: SingleValue<ISelectOption<Payments>>
	) => {
		if (!option) return;

		void setFieldValue('cardType', option.value);
		void setFieldValue('cardNumber', '');
		void setFieldValue('expiration', '');
		void setFieldValue('verificationCode', '');
		void setFieldValue('billingZip', '');
	};

	const handleCvvChange = (e: ChangeEvent<HTMLInputElement>) => {
		const validCvvLength =
			values.cardType === Payments.AMERICAN_EXPRESS
				? VALID_FIELDS_LENGTH.UNIQ_CVV
				: VALID_FIELDS_LENGTH.CVV;

		const isValidCvv = validateStringNumber(e.target.value, validCvvLength);

		if (!isValidCvv) return;

		handleChange(e);
	};

	const handleZipChange = (e: ChangeEvent<HTMLInputElement>) => {
		const isValidZip = validateStringNumber(
			e.target.value,
			VALID_FIELDS_LENGTH.ZIP
		);

		if (!isValidZip) return;

		handleChange(e);
	};

	const handleCardNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
		const isValidCardNumber = validateStringNumber(
			e.target.value,
			VALID_FIELDS_LENGTH.CARD_NUMBER
		);

		if (!isValidCardNumber) return;

		handleChange(e);
	};

	const handleChangeCardNumberVisibility = () => {
		setShowCardNumber((prev) => !prev);
	};
	const handleChangeCvvVisibility = () => {
		setShowCvv((prev) => !prev);
	};

	const cardNumberInputType = showCardNumber ? 'text' : 'password';
	const cvvInputType = showCvv ? 'text' : 'password';

	return (
		<div className="studio-form">
			<h4 className="studio-subtitle">
				This is the card we will use for any fees and balances for this studio.
				We will charge $1 to this card to validate it can be used.
			</h4>
			<div className="form-container">
				<SelectComponent
					id="cardType"
					label="Card Type"
					value={values.cardType}
					error={errors.cardType}
					touched={!!touched.cardType}
					selectPlaceholder="Card Type"
					onChange={handleCardTypeChange}
					selectOptions={paymentsSelectOptions}
				/>
			</div>
			<div className="acc-toggle-container">
				<InputField
					id="cardNumber"
					label="Card Number"
					value={values.cardNumber}
					placeholder="Card Number"
					type={cardNumberInputType}
					touched={!!touched.cardNumber}
					error={errors.cardNumber || ''}
					wrapperClassName="form-container"
					handleChange={handleCardNumberChange}
				/>
				<span
					className="acc-toggle-btn"
					onClick={handleChangeCardNumberVisibility}
				>
					{showCardNumber ? 'Hide' : 'Show'}
				</span>
			</div>
			<InputField
				id="expiration"
				label="Expiration"
				placeholder="MM/YY"
				value={values.expiration}
				touched={!!touched.expiration}
				error={errors.expiration || ''}
				wrapperClassName="form-container"
				handleChange={handleExpirationChange}
			/>
			<div className="form-col-container">
				<div className="acc-toggle-section">
					<InputField
						label="CVV"
						type={cvvInputType}
						id="verificationCode"
						placeholder="CVV Code"
						wrapperClassName="form-col"
						handleChange={handleCvvChange}
						value={values.verificationCode}
						touched={!!touched.verificationCode}
						error={errors.verificationCode || ''}
					/>
					<span className="acc-toggle-btn" onClick={handleChangeCvvVisibility}>
						{showCvv ? 'Hide' : 'Show'}
					</span>
				</div>
				<InputField
					id="billingZip"
					label="Zipcode"
					placeholder="Zipcode"
					value={values.billingZip}
					wrapperClassName="form-col"
					handleChange={handleZipChange}
					touched={!!touched.billingZip}
					error={errors.billingZip || ''}
				/>
			</div>
		</div>
	);
};
