import { FC, SyntheticEvent, useState } from 'react';

import { priceListFulfillmentDescriptions } from 'constants/priceLists/priceListFulfillmentDescriptions';
import { PriceListFulfillment } from 'constants/priceLists/priceListFulfillment';

import { IPatchBody } from 'api/models/requests/general/patchBody';
import {
	ErrorTypes,
	IErrorResponse,
} from 'api/models/responses/general/errorResponse';

import { validateOptionName } from 'utils/validations/priceLists/validateOptionName';
import { InputGrid } from 'components/FormControls/InputGrid';

interface IPriceListHeaderProps {
	name?: string;
	fulfillment: PriceListFulfillment;
	updatePriceList: (body: IPatchBody[]) => Promise<void>;
}

export const PriceListHeader: FC<IPriceListHeaderProps> = ({
	name,
	fulfillment,
	updatePriceList,
}) => {
	const [nameError, setNameError] = useState('');

	const fulfillmentDescription = priceListFulfillmentDescriptions[fulfillment];
	const fulfillmentValue = `${fulfillment} ${fulfillmentDescription}`;

	const updatePriceListNameAsync = async (value: string) => {
		try {
			const updatedNameField: IPatchBody = {
				value,
				path: 'name',
				op: 'replace',
			};

			const body: IPatchBody[] = [updatedNameField];

			await updatePriceList(body);
		} catch (error) {
			const { type, errors } = error as IErrorResponse;

			if (type === ErrorTypes.BusinessError) {
				const nameErrorMessage = errors.name[0];

				if (nameErrorMessage) {
					setNameError(nameErrorMessage);
				}
			}
		}
	};

	const handleNameChange = (e: SyntheticEvent<HTMLInputElement>) => {
		const { value } = e.currentTarget;

		const trimmedValue = value.trim();

		const validationMessage = validateOptionName(trimmedValue);

		if (validationMessage) {
			return setNameError(validationMessage);
		}

		void updatePriceListNameAsync(trimmedValue);
	};

	const clearNameError = () => {
		setNameError('');
	};

	return (
		<div className="price-form-container">
			<InputGrid
				isLazy
				touched
				error={nameError}
				defaultValue={name}
				className="autorow"
				label="Price List Name:"
				clearError={clearNameError}
				placeholder="Price List Name"
				handleLazyChange={handleNameChange}
			/>
			<InputGrid
				readOnly
				className="autorow"
				label="Order Fulfillment:"
				placeholder={fulfillmentValue}
			/>
		</div>
	);
};
