import { Dispatch } from '@reduxjs/toolkit';

import { ICreateStudioDefaultBackgroundOption } from 'api/models/requests/priceLists/studio/priceListOptions/createStudioDefaultBackgroundOption';
import { CreateImagoDefaultBackgroundOption } from 'api/models/requests/priceLists/imago/priceListOptions/createDefaultBackgroundOption';
import { ICreateStudioBackgroundOption } from 'api/models/requests/priceLists/studio/priceListOptions/createStudioBackgroundOption';
import { CreateImagoBackgroundOption } from 'api/models/requests/priceLists/imago/priceListOptions/createBackgroundOption';
import StudioBackgroundOptionsService from 'api/services/PriceListsService/studio/StudioBackgroundOptionsService';
import ImagoBackgroundOptionsService from 'api/services/PriceListsService/imago/ImagoBackgroundOptionsService';
import DefaultBackgroundsService from 'api/services/PriceListsService/DefaultBackgroundsService';
import { IErrorResponse } from 'api/models/responses/general/errorResponse';
import { IPatchBody } from 'api/models/requests/general/patchBody';

import { CUSTOM_BACKGROUND_OPTION_KEY } from 'constants/priceLists/selectOptions/backgroundFileCustomSelectOption';
import { initialBackgroundOptionErrors } from 'constants/priceLists/studio/initialBackgroundOptionErrors';

import { validateReferenceCode } from 'utils/validations/priceLists/validateReferenceCode';
import { validateRetailValue } from 'utils/validations/priceLists/validateRetailValue';
import { validateOptionName } from 'utils/validations/priceLists/validateOptionName';

import { RootState } from 'store/store';

import {
	setStudioBackgrounds,
	setDefaultBackgrounds,
	setCustomBackgroundFile,
	setIsReorderedBackgrounds,
	setImagoBackgrounds,
} from './priceListBackgroundSlice';

import {
	IImagoBackgroundOptionUI,
	IStudioBackgroundOptionUI,
	IUpdateBackgroundOption,
} from './types';

export const getDefaultBackgroundsAsync = () => async (dispatch: Dispatch) => {
	try {
		const data = await DefaultBackgroundsService.getDefaultBackgrounds();

		dispatch(setDefaultBackgrounds(data));
	} catch (error) {
		console.log(error);
	}
};

export const getImagoBackgroundsAsync =
	(priceListKey: number) => async (dispatch: Dispatch) => {
		try {
			const data =
				await ImagoBackgroundOptionsService.getImagoBackgroundOptions(
					priceListKey
				);

			const updatedBackgroundOptionsUI = data.map((backgroundOption) => ({
				...backgroundOption,
				errors: initialBackgroundOptionErrors,
			}));

			dispatch(setImagoBackgrounds(updatedBackgroundOptionsUI));
		} catch (error) {
			console.log(error);
		}
	};

export const saveStudioBackgroundsAsync =
	(priceListKey: number) =>
	async (dispatch: Dispatch, getState: () => RootState) => {
		const { priceListBackgrounds } = getState();

		const { studioBackgrounds, backgroundFileKey, customBackgroundFile } =
			priceListBackgrounds;

		const lastBackgroundOptionUI =
			studioBackgrounds[studioBackgrounds.length - 1];

		const { name, sequence, retailPrice, referenceCode } =
			lastBackgroundOptionUI;

		const slicedStudioBackgrounds = studioBackgrounds.slice(0, -1);

		const isCustomBackgroundOption =
			backgroundFileKey === CUSTOM_BACKGROUND_OPTION_KEY;

		const previewValidationMessage =
			isCustomBackgroundOption && !customBackgroundFile
				? 'Please upload a background file.'
				: '';

		const nameValidationMessage = validateOptionName(name);
		const retailPriceValidationMessage = validateRetailValue(retailPrice);
		const referenceCodeValidationMessage = validateReferenceCode(referenceCode);

		const isValid =
			!nameValidationMessage &&
			!retailPriceValidationMessage &&
			!referenceCodeValidationMessage &&
			!previewValidationMessage;

		if (!isValid) {
			const updatedStudioBackground: IStudioBackgroundOptionUI = {
				...lastBackgroundOptionUI,
				errors: {
					name: nameValidationMessage,
					retailPrice: retailPriceValidationMessage,
					thumbnailImageUrl: previewValidationMessage,
					referenceCode: referenceCodeValidationMessage,
				},
			};

			dispatch(
				setStudioBackgrounds([
					...slicedStudioBackgrounds,
					updatedStudioBackground,
				])
			);
			return;
		}

		try {
			const generalParams = { name, sequence, retailPrice, referenceCode };

			if (isCustomBackgroundOption) {
				if (!customBackgroundFile) return;

				const body: ICreateStudioBackgroundOption = {
					...generalParams,
					backgroundImage: customBackgroundFile,
				};

				const data =
					await StudioBackgroundOptionsService.createStudioBackgroundOption(
						priceListKey,
						body
					);

				const updatedStudioBackground: IStudioBackgroundOptionUI = {
					...data,
					errors: initialBackgroundOptionErrors,
				};

				dispatch(
					setStudioBackgrounds([
						...slicedStudioBackgrounds,
						updatedStudioBackground,
					])
				);
				dispatch(setCustomBackgroundFile(null));
			} else {
				const body: ICreateStudioDefaultBackgroundOption = {
					...generalParams,
					defaultPriceListBackgroundKey: backgroundFileKey,
				};

				const data =
					await StudioBackgroundOptionsService.createDefaultBackgroundOption(
						priceListKey,
						body
					);

				const updatedStudioBackground: IStudioBackgroundOptionUI = {
					...lastBackgroundOptionUI,
					...data,
				};

				dispatch(
					setStudioBackgrounds([
						...slicedStudioBackgrounds,
						updatedStudioBackground,
					])
				);
			}
		} catch (error) {
			const { errors } = error as IErrorResponse;

			const updatedStudioBackground: IStudioBackgroundOptionUI = {
				...lastBackgroundOptionUI,
				errors: {
					...initialBackgroundOptionErrors,
					...errors,
				},
			};

			dispatch(
				setStudioBackgrounds([
					...slicedStudioBackgrounds,
					updatedStudioBackground,
				])
			);
		}
	};

export const saveImagoBackgroundsAsync =
	(priceListKey: number) =>
	async (dispatch: Dispatch, getState: () => RootState) => {
		const { priceListBackgrounds } = getState();

		const { imagoBackgrounds, backgroundFileKey, customBackgroundFile } =
			priceListBackgrounds;

		const lastBackgroundOptionUI =
			imagoBackgrounds[imagoBackgrounds.length - 1];

		const { name, retailPrice, sequence } = lastBackgroundOptionUI;

		const slicedImagoBackgrounds = imagoBackgrounds.slice(0, -1);

		const isCustomBackgroundOption =
			backgroundFileKey === CUSTOM_BACKGROUND_OPTION_KEY;

		const previewValidationMessage =
			isCustomBackgroundOption && !customBackgroundFile
				? 'Please upload a background file.'
				: '';

		const nameValidationMessage = validateOptionName(name);
		const retailPriceValidationMessage = validateRetailValue(retailPrice);

		const isValid = !nameValidationMessage && !previewValidationMessage;

		if (!isValid) {
			const updatedImagoBackground: IImagoBackgroundOptionUI = {
				...lastBackgroundOptionUI,
				errors: {
					...lastBackgroundOptionUI.errors,
					name: nameValidationMessage,
					retailPrice: retailPriceValidationMessage,
					thumbnailImageUrl: previewValidationMessage,
				},
			};

			dispatch(
				setImagoBackgrounds([...slicedImagoBackgrounds, updatedImagoBackground])
			);
			return;
		}

		try {
			const generalParams = { name, sequence, retailPrice };

			if (isCustomBackgroundOption) {
				if (!customBackgroundFile) return;

				const body: CreateImagoBackgroundOption = {
					...generalParams,
					backgroundImage: customBackgroundFile,
				};

				const data =
					await ImagoBackgroundOptionsService.createImagoBackgroundOption(
						priceListKey,
						body
					);

				const updatedImagoBackground: IImagoBackgroundOptionUI = {
					...data,
					errors: initialBackgroundOptionErrors,
				};

				dispatch(
					setImagoBackgrounds([
						...slicedImagoBackgrounds,
						updatedImagoBackground,
					])
				);
				dispatch(setCustomBackgroundFile(null));
			} else {
				const body: CreateImagoDefaultBackgroundOption = {
					...generalParams,
					defaultPriceListBackgroundKey: backgroundFileKey,
				};

				const data =
					await ImagoBackgroundOptionsService.createDefaultBackgroundOption(
						priceListKey,
						body
					);

				const updatedImagoBackground: IImagoBackgroundOptionUI = {
					...lastBackgroundOptionUI,
					...data,
				};

				dispatch(
					setImagoBackgrounds([
						...slicedImagoBackgrounds,
						updatedImagoBackground,
					])
				);
			}
		} catch (error) {
			const { errors } = error as IErrorResponse;

			const updatedImagoBackground: IImagoBackgroundOptionUI = {
				...lastBackgroundOptionUI,
				errors: {
					...initialBackgroundOptionErrors,
					...errors,
				},
			};

			dispatch(
				setImagoBackgrounds([...slicedImagoBackgrounds, updatedImagoBackground])
			);
		}
	};

export const updateStudioBackgroundAsync =
	({
		value,
		sequence,
		fieldKey,
		validationMessage,
		backgroundOptionKey,
	}: IUpdateBackgroundOption) =>
	async (dispatch: Dispatch, getState: () => RootState) => {
		const { priceListBackgrounds } = getState();

		const { studioBackgrounds } = priceListBackgrounds;

		if (!backgroundOptionKey || validationMessage) {
			const updatedStudioBackgrounds = studioBackgrounds.map(
				(backgroundOptionUI) =>
					backgroundOptionUI.sequence === sequence
						? {
								...backgroundOptionUI,
								[fieldKey]: value,
								errors: {
									...backgroundOptionUI.errors,
									[fieldKey]: validationMessage,
								},
						  }
						: backgroundOptionUI
			);

			dispatch(setStudioBackgrounds(updatedStudioBackgrounds));
			return;
		}

		if (!backgroundOptionKey) return;

		try {
			const updatedField: IPatchBody = {
				value,
				op: 'replace',
				path: fieldKey,
			};

			const body: IPatchBody[] = [updatedField];

			const data = await StudioBackgroundOptionsService.updateBackgroundOption(
				backgroundOptionKey,
				body
			);

			const updatedStudioBackgrounds = studioBackgrounds.map(
				(backgroundOptionUI) =>
					backgroundOptionUI.priceListStudioFulfilledBackgroundOptionKey ===
					backgroundOptionKey
						? {
								...backgroundOptionUI,
								...data,
						  }
						: backgroundOptionUI
			);

			dispatch(setStudioBackgrounds(updatedStudioBackgrounds));
		} catch (error) {
			const { errors } = error as IErrorResponse;

			const updatedStudioBackgrounds = studioBackgrounds.map(
				(backgroundOptionUI) =>
					backgroundOptionUI.sequence === sequence
						? {
								...backgroundOptionUI,
								errors: {
									...backgroundOptionUI.errors,
									[fieldKey]: errors[fieldKey],
								},
						  }
						: backgroundOptionUI
			);

			dispatch(setStudioBackgrounds(updatedStudioBackgrounds));
		}
	};

export const updateImagoBackgroundAsync =
	({
		value,
		sequence,
		fieldKey,
		validationMessage,
		backgroundOptionKey,
	}: IUpdateBackgroundOption) =>
	async (dispatch: Dispatch, getState: () => RootState) => {
		const { priceListBackgrounds } = getState();

		const { imagoBackgrounds } = priceListBackgrounds;

		if (!backgroundOptionKey || validationMessage) {
			const updatedImagoBackgrounds = imagoBackgrounds.map(
				(backgroundOptionUI) =>
					backgroundOptionUI.sequence === sequence
						? {
								...backgroundOptionUI,
								[fieldKey]: value,
								errors: {
									...backgroundOptionUI.errors,
									[fieldKey]: validationMessage,
								},
						  }
						: backgroundOptionUI
			);

			dispatch(setImagoBackgrounds(updatedImagoBackgrounds));
			return;
		}

		if (!backgroundOptionKey) return;

		try {
			const updatedField: IPatchBody = {
				value,
				op: 'replace',
				path: fieldKey,
			};

			const body: IPatchBody[] = [updatedField];

			const data = await ImagoBackgroundOptionsService.updateBackgroundOption(
				backgroundOptionKey,
				body
			);

			const updatedImagoBackgrounds = imagoBackgrounds.map(
				(backgroundOptionUI) =>
					backgroundOptionUI.priceListImagoFulfilledBackgroundKey ===
					backgroundOptionKey
						? {
								...backgroundOptionUI,
								...data,
						  }
						: backgroundOptionUI
			);

			dispatch(setImagoBackgrounds(updatedImagoBackgrounds));
		} catch (error) {
			const { errors } = error as IErrorResponse;

			const updatedImagoBackgrounds = imagoBackgrounds.map(
				(backgroundOptionUI) =>
					backgroundOptionUI.sequence === sequence
						? {
								...backgroundOptionUI,
								errors: {
									...backgroundOptionUI.errors,
									[fieldKey]: errors[fieldKey],
								},
						  }
						: backgroundOptionUI
			);

			dispatch(setImagoBackgrounds(updatedImagoBackgrounds));
		}
	};

export const deleteStudioBackgroundAsync =
	(sequence: number, backgroundOptionKey?: number) =>
	async (dispatch: Dispatch, getState: () => RootState) => {
		const { priceListBackgrounds } = getState();

		const { studioBackgrounds } = priceListBackgrounds;

		const updatedStudioBackgrounds = studioBackgrounds.filter(
			(backgroundOptionUI) => backgroundOptionUI.sequence !== sequence
		);

		dispatch(setStudioBackgrounds(updatedStudioBackgrounds));
		dispatch(setCustomBackgroundFile(null));

		if (backgroundOptionKey) {
			try {
				await StudioBackgroundOptionsService.deleteBackgroundOption(
					backgroundOptionKey
				);
			} catch (error) {
				console.log(error);
			}
		}
	};

export const deleteImagoBackgroundAsync =
	(sequence: number, backgroundOptionKey?: number) =>
	async (dispatch: Dispatch, getState: () => RootState) => {
		const { priceListBackgrounds } = getState();

		const { imagoBackgrounds } = priceListBackgrounds;

		const updatedImagoBackgrounds = imagoBackgrounds.filter(
			(backgroundOptionUI) => backgroundOptionUI.sequence !== sequence
		);

		dispatch(setImagoBackgrounds(updatedImagoBackgrounds));
		dispatch(setCustomBackgroundFile(null));

		if (backgroundOptionKey) {
			try {
				await ImagoBackgroundOptionsService.deleteBackgroundOption(sequence);
			} catch (error) {
				console.log(error);
			}
		}
	};

export const reorderStudioBackgroundsAsync =
	(priceListKey: number) =>
	async (dispatch: Dispatch, getState: () => RootState) => {
		const { priceListBackgrounds } = getState();

		const { studioBackgrounds } = priceListBackgrounds;

		const body = studioBackgrounds.reduce<Record<number, number>>(
			(acc, { priceListStudioFulfilledBackgroundOptionKey, sequence }) =>
				priceListStudioFulfilledBackgroundOptionKey
					? {
							...acc,
							[priceListStudioFulfilledBackgroundOptionKey]: sequence,
					  }
					: acc,
			{}
		);

		try {
			await StudioBackgroundOptionsService.reorderBackgroundOptions(
				priceListKey,
				body
			);
		} catch (error) {
			console.log(error);
		}

		dispatch(setIsReorderedBackgrounds(false));
	};

export const reorderImagoBackgroundsAsync =
	(priceListKey: number) =>
	async (dispatch: Dispatch, getState: () => RootState) => {
		const { priceListBackgrounds } = getState();

		const { imagoBackgrounds } = priceListBackgrounds;

		const body = imagoBackgrounds.reduce<Record<number, number>>(
			(acc, { priceListImagoFulfilledBackgroundKey, sequence }) =>
				priceListImagoFulfilledBackgroundKey
					? {
							...acc,
							[priceListImagoFulfilledBackgroundKey]: sequence,
					  }
					: acc,
			{}
		);

		try {
			await ImagoBackgroundOptionsService.reorderBackgroundOptions(
				priceListKey,
				body
			);
		} catch (error) {
			console.log(error);
		}

		dispatch(setIsReorderedBackgrounds(false));
	};

export const deleteStudioBackgroundsAsync =
	(priceListKey: number) => async (dispatch: Dispatch) => {
		try {
			dispatch(setStudioBackgrounds([]));
			dispatch(setCustomBackgroundFile(null));

			await StudioBackgroundOptionsService.deleteBackgroundOptions(
				priceListKey
			);
		} catch (error) {
			console.log(error);
		}
	};
