import { DragEvent, FC, useState } from 'react';

import { DeleteControlColumn } from 'pages/PriceLists/components/DeleteControlColumn';
import { ToggleArrowColumn } from 'pages/PriceLists/components/ToggleArrowColumn';
import { RetailValueColumn } from 'pages/PriceLists/components/RetailValueColumn';
import { SimpleColumn } from 'pages/PriceLists/components/SimpleColumn';
import { NameColumn } from 'pages/PriceLists/components/NameColumn';
import { IProductUI } from 'pages/PriceLists/types/imago/productUI';

import { useDragAndDrop } from 'hooks/useDragAndDrop';
import { ISelectOption } from 'types/ui/select';

import { ProductSelectColumn } from '../ProductSelectColumn';
import { ProductContent } from './components/ProductContent';
import {
	UpdateProductHandler,
	DeleteProductHandler,
	UpdateCatalogProduct,
	ClearProductErrorHandler,
	DeleteProductImageHandler,
	UploadProductImageHandler,
	ChangeProductSequenceHandler,
} from '../../hooks/useImagoProducts';

interface IProductProps {
	productUI: IProductUI;
	previewImageFile: File | null;
	showMaxNumberOfPoses?: boolean;
	productSelectPlaceholder: string;
	updateProduct: UpdateProductHandler;
	deleteProduct: DeleteProductHandler;
	updateCatalogProduct: UpdateCatalogProduct;
	clearProductError: ClearProductErrorHandler;
	uploadProductImage: UploadProductImageHandler;
	deleteProductImage: DeleteProductImageHandler;
	catalogProductsSelectOptions: ISelectOption[];
	changeProductSequence: ChangeProductSequenceHandler;
}

export const Product: FC<IProductProps> = ({
	productUI,
	updateProduct,
	deleteProduct,
	previewImageFile,
	clearProductError,
	uploadProductImage,
	deleteProductImage,
	updateCatalogProduct,
	showMaxNumberOfPoses,
	changeProductSequence,
	productSelectPlaceholder,
	catalogProductsSelectOptions,
}) => {
	const [showContent, setShowContent] = useState(false);

	const {
		draggable,
		handleDrop,
		handleDragEnd,
		handleDragOver,
		handleMouseDown,
		handleDragStart,
	} = useDragAndDrop([], changeProductSequence);

	const handleToggleShowContent = () => {
		setShowContent(!showContent);
	};

	const {
		name,
		profit,
		errors,
		sequence,
		serviceFee,
		retailPrice,
		fulfillment,
		catalogProductKey,
		priceListImagoFulfilledProductKey: productKey,
	} = productUI;

	const {
		name: nameError,
		retailPrice: retailPriceError,
		catalogProductKey: catalogProductKeyError,
	} = errors;

	const productKeyString = productKey?.toString() || '';

	const handleDeleteProduct = () => {
		void deleteProduct({ sequence, productKey });
	};

	const onDragStart = (e: DragEvent<HTMLTableSectionElement>) => {
		if (!productKeyString) return;

		handleDragStart(e, productKeyString);
	};

	const updateName = (value: string, validationMessage: string) => {
		void updateProduct({
			value,
			sequence,
			productKey,
			fieldKey: 'name',
			validationMessage,
		});
	};

	const clearNameError = () => {
		if (!nameError) return;

		clearProductError({ sequence, fieldKey: 'name' });
	};

	const updateRetailPrice = (value: number, validationMessage: string) => {
		void updateProduct({
			value,
			sequence,
			productKey,
			fieldKey: 'retailPrice',
			validationMessage,
		});
	};

	const clearRetailPriceError = () => {
		if (!retailPriceError) return;

		clearProductError({ sequence, fieldKey: 'retailPrice' });
	};

	const unsavedProduct = !productKey;

	const updateCatalogProductKey = async (value: number) => {
		if (unsavedProduct) {
			await updateProduct({
				value,
				sequence,
				productKey,
				fieldKey: 'catalogProductKey',
			});
			return;
		}

		await updateCatalogProduct(productKey, value);
	};

	const clearCatalogProductKeyError = () => {
		if (!catalogProductKeyError) return;

		clearProductError({ sequence, fieldKey: 'catalogProductKey' });
	};

	const showBurger = !unsavedProduct && !showContent;
	const showContentBurger = !unsavedProduct && showContent;

	const uniqPreviewImageFile = unsavedProduct ? previewImageFile : null;

	return (
		<tbody
			onDrop={handleDrop}
			id={productKeyString}
			draggable={draggable}
			onDragStart={onDragStart}
			onDragEnd={handleDragEnd}
			onDragOver={handleDragOver}
			data-drag-sequence={sequence}
			className="price-option-tbody"
		>
			<tr>
				<ToggleArrowColumn
					showContent={showContent}
					showBurgerBtn={showBurger}
					handleMouseDown={handleMouseDown}
					handleToggleShowContent={handleToggleShowContent}
				/>
				<NameColumn
					name={name}
					nameError={nameError}
					updateName={updateName}
					clearError={clearNameError}
				/>
				<RetailValueColumn
					retailValue={retailPrice}
					clearError={clearRetailPriceError}
					retailValueError={retailPriceError}
					updateRetailValue={updateRetailPrice}
				/>
				<ProductSelectColumn
					error={catalogProductKeyError}
					catalogProductKey={catalogProductKey}
					placeholder={productSelectPlaceholder}
					updateSelectValue={updateCatalogProductKey}
					selectOptions={catalogProductsSelectOptions}
					clearProductError={clearCatalogProductKeyError}
				/>
				<SimpleColumn value={fulfillment} />
				<SimpleColumn value={serviceFee} />
				<SimpleColumn value={profit} />
				<DeleteControlColumn handleDelete={handleDeleteProduct} />
			</tr>
			{showContent && (
				<ProductContent
					productUI={productUI}
					updateProduct={updateProduct}
					showBurger={showContentBurger}
					handleMouseDown={handleMouseDown}
					clearProductError={clearProductError}
					previewImageFile={uniqPreviewImageFile}
					uploadProductImage={uploadProductImage}
					deleteProductImage={deleteProductImage}
					showMaxNumberOfPoses={showMaxNumberOfPoses}
				/>
			)}
		</tbody>
	);
};
