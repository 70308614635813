import { object, string } from 'yup';

import { requiredFieldMessage } from 'constants/general/validation/generalMessages';
import {
	orgNameRegExMessage,
	organizationNameRegEx,
} from 'constants/organizations/validation';

export const copyCampaignValidationSchema = object().shape({
	name: string()
		.required(requiredFieldMessage)
		.matches(organizationNameRegEx, orgNameRegExMessage),
});
