import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { utc } from 'moment';

import { projectTimelineEventTypeIcons } from 'constants/projects/timeline/projectTimelineEventTypeIcons';
import { projectTimelineColors } from 'constants/projects/timeline/projectTimelineColors';
import { salesUI } from 'constants/projects/salesUI';

import { ITimelineItem } from 'api/models/responses/projects/timeline';
import { SaleTypes } from 'api/models/responses/projects/sales';
import ProjectsService from 'api/services/ProjectsService';

import { Modal, ModalRef } from 'components/Modal';
import { Loader } from 'components/Loader';

import { TimelineSection } from './components/TimelineSection';
import { TimelineItem } from './components/TimelineItem';
import { SaleDetails } from './components/SaleDetails';
import { IToggleTimelineContainerProps } from '..';
import { Agreement } from 'components/Modal/components/Agreement';

interface ITimelineProps extends IToggleTimelineContainerProps {
	isPending: boolean;
	timeline: ITimelineItem[];
	setIsPending: (isPending: boolean) => void;
	setTimeline: (timeline: ITimelineItem[]) => void;
}

interface ITimelineUI {
	icon: string;
	lineColor: string;
	saleColor: string;
	isSection: boolean;
	iconHelper: string;
	year: string | null;
	timeline: ITimelineItem | null;
}

interface IAcc {
	list: ITimelineUI[];
	saleLineColor: string;
	saleType: SaleTypes | null;
	secondSaleLineColor: string;
	secondSaleType: SaleTypes | null;
}

export const Timeline: FC<ITimelineProps> = ({
	timeline,
	isPending,
	projectKey,
	setTimeline,
	setIsPending,
	isUpdateTimeline,
	setIsUpdateProject,
	setIsUpdateTimeline,
}) => {
	const editModalRef = useRef<ModalRef>(null);
	const editErrorModalRef = useRef<ModalRef>(null);

	const [saleKey, setSaleKey] = useState<number | null>(null);
	const [saleTypeState, setSaleTypeState] = useState<SaleTypes | null>(null);

	const [editErrorMessage, setEditErrorMessage] = useState('');

	const handleEditSale = (saleKeyParam: number, saleTypeParam: SaleTypes) => {
		setSaleKey(saleKeyParam);
		setSaleTypeState(saleTypeParam);
		editModalRef?.current?.open();
	};

	const hideEditSale = () => {
		editModalRef?.current?.close();
	};

	const showEditErrorModal = () => {
		editErrorModalRef?.current?.open();
	};

	const hideEditErrorModal = () => {
		editErrorModalRef?.current?.close();
		setEditErrorMessage('');
	};

	const initialAcc: IAcc = {
		list: [],
		saleType: null,
		saleLineColor: '',
		secondSaleType: null,
		secondSaleLineColor: '',
	};

	const timelineUI = timeline.reduce<IAcc>((acc, timelineParam, index) => {
		const { saleType, saleLineColor, secondSaleType, secondSaleLineColor } =
			acc;

		const { date, saleModel, title, projectEventType } = timelineParam;

		const prevTimelineParam = timeline[index - 1];

		const year = utc(date).format('YY');

		const yearItem = {
			year,
			icon: '',
			saleColor: '',
			lineColor: '',
			iconHelper: '',
			timeline: null,
			isSection: false,
		};

		if (!index) {
			acc.list.push(yearItem);
		}

		if (prevTimelineParam) {
			const prevYear = utc(prevTimelineParam.date).format('YY');

			if (prevYear !== year) {
				acc.list.push(yearItem);
			}
		}

		const getIcon = projectTimelineEventTypeIcons[projectEventType];
		const { icon, iconHelper } = getIcon(title);

		if (saleModel) {
			const isSaleTypeRepeated = saleType === saleModel.saleType;
			const isSecondSaleTypeRepeated = secondSaleType === saleModel.saleType;

			const colorParams = projectTimelineColors[saleModel.saleType];

			const baseItem = {
				icon,
				year: null,
				iconHelper,
				isSection: true,
				timeline: timelineParam,
				saleColor: colorParams.saleColor,
				lineColor: colorParams.saleLineColor,
			};

			if (!isSaleTypeRepeated && !colorParams.isOverlapSale) {
				acc.saleType = saleModel.saleType;
				acc.saleLineColor = colorParams.saleLineColor;

				baseItem.lineColor = `${colorParams.saleLineColor} ${secondSaleLineColor}`;

				acc.list.push(baseItem);

				return acc;
			}

			if (!isSecondSaleTypeRepeated && colorParams.isOverlapSale) {
				acc.secondSaleType = saleModel.saleType;
				acc.secondSaleLineColor = colorParams.saleLineColor;

				baseItem.lineColor = `${saleLineColor} ${colorParams.saleLineColor}`;

				acc.list.push(baseItem);

				return acc;
			}

			const baseSaleRepeatedItem = {
				isSection: false,
				iconHelper: colorParams.saleColor,
			};

			if (isSaleTypeRepeated) {
				acc.saleLineColor = '';
				acc.saleType = null;

				acc.list.push({
					...baseItem,
					...baseSaleRepeatedItem,
					lineColor: secondSaleLineColor,
				});

				return acc;
			}

			if (isSecondSaleTypeRepeated) {
				acc.secondSaleType = null;
				acc.secondSaleLineColor = '';

				acc.list.push({
					...baseItem,
					...baseSaleRepeatedItem,
					lineColor: saleLineColor,
				});

				return acc;
			}
		}

		acc.list.push({
			icon,
			year: null,
			iconHelper,
			saleColor: '',
			isSection: false,
			timeline: timelineParam,
			lineColor: `${saleLineColor} ${secondSaleLineColor}`,
		});

		return acc;
	}, initialAcc).list;

	const TimelineList = timelineUI.map(
		(
			{
				icon,
				year,
				isSection,
				lineColor,
				saleColor,
				iconHelper,
				timeline: timelineParam,
			},
			index
		) => {
			if (year) {
				return <TimelineItem year={year} key={`${year}-index`} />;
			}

			if (!timelineParam) return null;

			const {
				date,
				value,
				title,
				saleModel,
				dateIsEstimate,
				projectEventType,
				dateIsEstimateComment,
			} = timelineParam;

			const datePreview = utc(date).format('MMM DD');

			if (isSection && saleModel) {
				return (
					<TimelineSection
						icon={icon}
						title={title}
						saleColor={saleColor}
						saleColorLine={lineColor}
						datePreview={datePreview}
						saleEnds={saleModel.saleEnds}
						dateIsEstimate={dateIsEstimate}
						priceList={saleModel.priceList}
						saleBegins={saleModel.saleBegins}
						projectGreeting={saleModel.projectGreeting}
						key={`${date}-${projectEventType}-${index}`}
						dateIsEstimateComment={dateIsEstimateComment}
						isEndDateEstimated={saleModel?.isEndDateEstimated}
						isStartDateEstimated={saleModel?.isStartDateEstimated}
						handleEditSale={() =>
							handleEditSale(saleModel.saleKey, saleModel.saleType)
						}
						orderConfirmationMessage={saleModel.orderConfirmationMessage}
					/>
				);
			}

			return (
				<TimelineItem
					icon={icon}
					title={title}
					value={value}
					iconHelper={iconHelper}
					saleLineColor={lineColor}
					datePreview={datePreview}
					dateIsEstimate={dateIsEstimate}
					key={`${date}-${projectEventType}-${index}`}
					dateIsEstimateComment={dateIsEstimateComment}
				/>
			);
		}
	);

	const getProjectTimelineAsync = useCallback(async () => {
		if (!projectKey || (timeline.length && !isUpdateTimeline)) return;

		setIsPending(true);

		try {
			const data = await ProjectsService.getProjectTimeline(projectKey);

			setTimeline(data.value);
		} catch (error) {
			console.log(error);
		}

		setIsPending(false);
		setIsUpdateTimeline(false);
	}, [projectKey, timeline, isUpdateTimeline]);

	useEffect(() => {
		void getProjectTimelineAsync();
	}, [getProjectTimelineAsync]);

	useEffect(() => {
		if (!editErrorMessage) return;

		showEditErrorModal();
	}, [editErrorMessage]);

	const saleUI = saleTypeState && salesUI[saleTypeState];

	const EditSaleTitle = saleUI && (
		<div className="project-journey-item preset-journey-symbol">
			<i className={`project-journey-symbol ${saleUI.icon}`} />
			<b>{saleUI.title} Details</b>
		</div>
	);

	if (isPending) return <Loader />;

	return (
		<div className="timeline">
			<ul className="timeline-list">{TimelineList}</ul>
			<Modal ref={editModalRef} title={EditSaleTitle} className="full">
				<SaleDetails
					saleKey={saleKey}
					hideEditSale={hideEditSale}
					setIsUpdateProject={setIsUpdateProject}
					setEditErrorMessage={setEditErrorMessage}
					setIsUpdateTimeline={setIsUpdateTimeline}
				/>
			</Modal>
			<Modal
				ref={editErrorModalRef}
				title="Edit Sale Error"
				subtitle={editErrorMessage}
			>
				<Agreement handleAgreement={hideEditErrorModal} />
			</Modal>
		</div>
	);
};
