import { useCallback, useEffect, useRef, useState } from 'react';
import { useToggleSection } from 'hooks/useToggleSection';

import { ISubscription } from 'api/models/responses/subscriptions/subscription';
import SubscriptionsService from 'api/services/SubscriptionsService';

import { normalizeDigit } from 'utils/ui/normalizeDigit';

import { Button } from 'components/FormControls/Button';
import { ToggleSection } from 'components/ToggleSection';
import { Modal, ModalRef } from 'components/Modal';

import { UpgradeYourPlan } from 'pages/Accounting/modals/UpgradeYourPlan';

export const PlanInformation = () => {
	const { isOpen, handleToggle } = useToggleSection(true);
	const [planInfo, setPlanInfo] = useState<ISubscription | null>(null);

	const upgradePlanRef = useRef<ModalRef>(null);

	const showUpgradePlanModal = () => {
		upgradePlanRef?.current?.open();
	};

	const hideUpgradePlanModal = () => {
		upgradePlanRef?.current?.close();
	};

	const getPlanInfo = useCallback(async () => {
		try {
			const data = await SubscriptionsService.getSubscription();
			setPlanInfo(data);
		} catch (error) {
			console.log(error);
		}
	}, []);

	useEffect(() => {
		void getPlanInfo();
	}, [getPlanInfo]);

	return (
		<div className="accing-toggle-section">
			<ToggleSection
				title="Plan Information"
				isOpen={isOpen}
				className="accing-toggle-header"
				handleToggle={handleToggle}
			>
				<div className="accing-wrapper">
					<div className="accing-list-wrapper">
						<ul className="accing-list">
							<li className="accing-item">
								Plan:
								<span>
									<b>{planInfo?.name}</b>
								</span>
							</li>
							<li className="accing-item">
								Subscription: <span>${planInfo?.subscriptionLevelKey}</span>
							</li>
							<li className="accing-item">
								Studio Fee: <span>{planInfo?.exceededMaxStorageFee}%</span>
							</li>
							<li className="accing-item">
								Auto Fee: <span>{planInfo?.autoFulfillServiceFeePercent}%</span>
							</li>
							<li className="accing-item">
								Images:
								<span>
									{normalizeDigit({
										value: planInfo?.maxImageCount,
										isPrice: false,
										minimumFractionDigits: 0,
									})}
								</span>
							</li>
						</ul>
						<Button
							className="btn-primary accing-btn"
							value="Upgrade Now"
							handleClick={showUpgradePlanModal}
						/>
					</div>
				</div>
			</ToggleSection>
			<Modal
				title="Upgrade Your Plan"
				subtitle="Choose a plan that best suits your business needs"
				ref={upgradePlanRef}
			>
				<UpgradeYourPlan closeModal={hideUpgradePlanModal} />
			</Modal>
		</div>
	);
};
