import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useCallback, useEffect, useRef, useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build';

import { organizationStatisticPriceKeys } from 'constants/organizations/organizationStatisticPriceKeys';
import { organizationStatisticKeys } from 'constants/organizations/organizationStatisticKeys';

import { IRejectDeleteOrganization } from 'api/models/responses/organizations/rejectDeleteOrganization';
import { IOrganizationStatistic } from 'api/models/responses/organizations/organizationStatistic';
import { IOrganizationDetails } from 'api/models/responses/organizations/organizationDetails';
import { IProjectItem } from 'api/models/responses/projects/projectItem';
import { IPatchBody } from 'api/models/requests/general/patchBody';
import OrganizationService from 'api/services/OrganizationService';

import { ConfirmDelete } from 'components/Modal/components/ConfirmDelete';
import { Agreement } from 'components/Modal/components/Agreement';
import { ToggleSection } from 'components/ToggleSection';
import { Button } from 'components/FormControls/Button';
import { TextEditor } from 'components/TextEditor';
import { Modal, ModalRef } from 'components/Modal';
import { PanelData } from 'components/PanelData';

import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useToggleSection } from 'hooks/useToggleSection';
import { useToastify } from 'hooks/useToastify';

import { IBreadcrumbs, breadcrumbsSelector } from 'store/breadcrumbs';
import {
	deleteOrganizationAsync,
	createdOrganizationSelector,
} from 'store/organizations';

import { useOrganizationsBreadcrumbs } from 'pages/Organizations/hooks/useOrganizationsBreadcrumbs';

import { DeleteOrganizationReject } from './components/DeleteOrganizationReject';
import { OrganizationProjects } from './components/OrganizationProjects';
import { OrganizationDetails } from './components/OrganizationDetails';

export const OrganizationDetailsPage = () => {
	const confirmDeleteModalRef = useRef<ModalRef>(null);
	const successDeleteModalRef = useRef<ModalRef>(null);
	const deleteErrorModalRef = useRef<ModalRef>(null);
	const editorRef = useRef<CKEditor<Editor>>(null);

	const createdOrganization = useAppSelector(createdOrganizationSelector);
	const breadcrumbsState = useAppSelector(breadcrumbsSelector);

	const [organizationDetails, setOrganizationDetails] =
		useState<IOrganizationDetails | null>(createdOrganization);

	const [organizationStatistic, setOrganizationStatistic] =
		useState<IOrganizationStatistic | null>(null);

	const [activeChildItems, setActiveChildItems] = useState<IProjectItem[]>([]);

	const { organizationId } = useParams();
	const { pathname } = useLocation();
	const navigate = useNavigate();

	const dispatch = useAppDispatch();

	const { isOpen, handleToggle } = useToggleSection(false);
	const { showSuccessToastify } = useToastify();

	const organizationKeyParam = (organizationId && +organizationId) || 0;

	const handlePermanentlyDeleteOrganization = async () => {
		if (!organizationDetails) return;

		try {
			await dispatch(
				deleteOrganizationAsync(organizationDetails.organizationKey)
			);

			successDeleteModalRef.current?.open();
		} catch (error) {
			const { isDeleted, activeChildItems: activeChildItemsRes } =
				error as IRejectDeleteOrganization;

			if (!isDeleted) {
				setActiveChildItems(activeChildItemsRes);

				deleteErrorModalRef.current?.open();
				confirmDeleteModalRef.current?.close();
			}
		}
	};

	const showConfirmDeleteModal = () => {
		confirmDeleteModalRef.current?.open();
	};

	const hideDeleteErrorModal = () => {
		deleteErrorModalRef.current?.close();
	};

	const hideConfirmDeleteModal = () => {
		confirmDeleteModalRef.current?.close();
	};

	const handleSuccessDelete = () => {
		successDeleteModalRef.current?.close();

		const organizationsPath =
			breadcrumbsState[breadcrumbsState.length - 2].path;

		navigate(organizationsPath);
	};

	const handleSaveTextEditor = () => {
		if (!organizationDetails) return;

		const currentEditorValue = editorRef.current?.editor?.data.get();

		const updatedOrganizationFields: IPatchBody = {
			op: 'replace',
			path: 'greetingMessage',
			value: currentEditorValue || '',
		};

		const organizationBody: IPatchBody[] = [updatedOrganizationFields];

		OrganizationService.partialOrganizationUpdate(
			organizationDetails.organizationKey,
			organizationBody
		)
			.then((data) => {
				setOrganizationDetails(data);

				showSuccessToastify({
					title: 'Page updated',
					message: 'Your updates have been published.',
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const organizationNameUI = organizationDetails?.organizationName || '';

	const organizationDetailsBreadcrumbs: IBreadcrumbs = {
		isActive: true,
		path: pathname,
		title: organizationDetails?.organizationName || '',
	};

	useOrganizationsBreadcrumbs(
		!!organizationDetails?.isActive,
		organizationDetailsBreadcrumbs
	);

	const getOrganizationDetails = useCallback(async () => {
		if (!organizationKeyParam) return;

		if (createdOrganization?.organizationKey === organizationKeyParam) {
			return setOrganizationDetails(createdOrganization);
		}

		try {
			const data = await OrganizationService.getOrganizationDetails(
				organizationKeyParam
			);

			setOrganizationDetails(data);
		} catch (error) {
			console.log(error);
		}
	}, [organizationKeyParam, createdOrganization?.organizationKey]);

	const getOrganizationStatistic = useCallback(async () => {
		if (!organizationKeyParam) return;

		try {
			const data = await OrganizationService.getOrganizationStatistic(
				organizationKeyParam
			);

			setOrganizationStatistic(data);
		} catch (error) {
			console.log(error);
		}
	}, [organizationKeyParam]);

	useEffect(() => {
		void getOrganizationDetails();
	}, [getOrganizationDetails]);

	useEffect(() => {
		void getOrganizationStatistic();
	}, [getOrganizationStatistic]);

	return (
		<div className="org-details org">
			<PanelData
				statistic={organizationStatistic}
				statisticKeys={organizationStatisticKeys}
				statisticPriceKeys={organizationStatisticPriceKeys}
			/>
			<OrganizationDetails
				organizationDetails={organizationDetails}
				setOrganizationDetails={setOrganizationDetails}
			/>
			<ToggleSection
				isOpen={isOpen}
				title="Organization Page"
				handleToggle={handleToggle}
				className="org-details-header editor"
			>
				<TextEditor
					editorRef={editorRef}
					handleSave={handleSaveTextEditor}
					initialContent={organizationDetails?.greetingMessage || ''}
				/>
			</ToggleSection>
			<OrganizationProjects organizationKey={organizationKeyParam} />
			<Button
				handleClick={showConfirmDeleteModal}
				className="btn-secondary org-delete-btn"
				value="Delete this Organization and Projects"
			/>
			<Modal
				ref={confirmDeleteModalRef}
				title="Delete Organization"
				subtitle={`Are you sure you would like to delete the organization ${organizationNameUI} and it’s projects? This action cannot be undone.`}
				className="modal-info"
			>
				<ConfirmDelete
					hideConfirmDeleteModal={hideConfirmDeleteModal}
					handlePermanentlyDelete={handlePermanentlyDeleteOrganization}
				/>
			</Modal>
			<Modal
				className="modal-info"
				ref={successDeleteModalRef}
				title="Organization Deleted"
				subtitle={`The organization ${organizationNameUI} has been deleted.`}
			>
				<Agreement handleAgreement={handleSuccessDelete} />
			</Modal>
			<Modal
				ref={deleteErrorModalRef}
				title="Delete Organization"
				subtitle="This organization cannot be deleted. The following projects are associated with this organization. You can delete this organization once the following projects are deleted or expired for more than 60 days."
			>
				<DeleteOrganizationReject
					hideModal={hideDeleteErrorModal}
					activeChildItems={activeChildItems}
				/>
			</Modal>
		</div>
	);
};
