import { Dispatch } from '@reduxjs/toolkit';

import { IProjectCreationFormParams } from 'api/models/requests/projects/projectCreationForm';
import ProjectsService from 'api/services/ProjectsService';

import { getSubjectFormQuestionsUI } from 'utils/subjectFormQuestions/getSubjectFormQuestionsUI';
import { setComingSoon, setLatestProjects } from 'store/dashboard';

import { setProjectCreationForm } from './projectsSlice';
import { IProjectCreationFormState } from './types';

export const getComingSoonAsync = () => async (dispatch: Dispatch) => {
	try {
		const data = await ProjectsService.getComingSoonAsync();

		dispatch(setComingSoon(data));
	} catch (error) {
		console.log(error);
	}
};

export const getLatestProjectsAsync = () => async (dispatch: Dispatch) => {
	try {
		const data = await ProjectsService.getLatestProjects();

		dispatch(setLatestProjects(data));
	} catch (error) {
		console.log(error);
	}
};

export const getReferencedItemsAsync =
	(projectCreationParams: IProjectCreationFormParams) =>
	async (dispatch: Dispatch) => {
		const projectCreationForm = await ProjectsService.getReferencedItems(
			projectCreationParams
		);

		if (!projectCreationForm) return;

		const subjectFormQuestionsUI = getSubjectFormQuestionsUI(
			projectCreationForm.subjectFormQuestions
		);

		const updatedProjectCreationForm: IProjectCreationFormState = {
			...projectCreationForm,
			subjectFormQuestions: subjectFormQuestionsUI,
		};

		dispatch(setProjectCreationForm(updatedProjectCreationForm));
	};
