import { FC, useCallback, useEffect, useState } from 'react';

import { initialPriceListFormQuestionErrors } from 'constants/priceLists/studio/priceListPackages/initialPackageFormQuestionErrors';
import { initialPackageErrors } from 'constants/priceLists/studio/priceListPackages/initialPackageErrors';
import { PoseAvailableFor } from 'constants/priceLists/poseAvailableFor/poseAvailableFor';
import { StudioPriceListTabs } from 'constants/priceLists/tabs/studioPriceListTabs';

import { IBackgroundOptionsVisibilityProps } from 'pages/PriceLists/types/studio/priceListOptions/backgroundOptionsVisibilityProps';
import { IPriceListFormQuestionUI } from 'pages/PriceLists/types/studio/priceListFormQuestionUI';
import { useCreatePriceListOptionBtn } from 'pages/PriceLists/hooks/useCreatePriceListOptionBtn';
import { PriceListControls } from 'pages/PriceLists/components/PriceListControls';
import {
	IPackageUI,
	IDeletePackage,
	IUpdatePackage,
	IClearPackageErrors,
	IUploadPackageImage,
} from 'pages/PriceLists/types/studio/priceListPackageUI';
import {
	PriceTable,
	IHeaderConfig,
} from 'pages/PriceLists/components/PriceTable';

import { ICreatePackageBody } from 'api/models/requests/priceLists/studio/priceListPackages/createPackageBody';
import { IGeneralFormQuestion } from 'api/models/responses/general/generalFormQuestion';
import PackagesService from 'api/services/PriceListsService/studio/PackagesService';
import { IErrorResponse } from 'api/models/responses/general/errorResponse';
import { IPatchBody } from 'api/models/requests/general/patchBody';

import { initialStudioIsBackgroundRequiredSelector } from 'store/priceLists/priceListBackgrounds';
import { initialRequiredPoseOptionsSelector } from 'store/priceLists/studio/priceListPoseOptions';

import { validateReferenceCode } from 'utils/validations/priceLists/validateReferenceCode';
import { validateRetailValue } from 'utils/validations/priceLists/validateRetailValue';
import { validateDescription } from 'utils/validations/priceLists/validateDescription';
import { validateOptionName } from 'utils/validations/priceLists/validateOptionName';
import { validatePoints } from 'utils/validations/priceLists/studio/validatePoints';
import { changeEntitiesSequence } from 'utils/dragAndDrop/changeEntitiesSequence';

import { useAsyncOptimizedCheckbox } from 'hooks/formControls/useAsyncOptimizedCheckbox';
import { IChangeSequencePayload } from 'hooks/useDragAndDrop';
import { useAppSelector } from 'hooks/redux/useAppSelector';

import { Checkbox } from 'components/FormControls/Checkbox';
import { Button } from 'components/FormControls/Button';
import { NoItemsFound } from 'components/NoItemsFound';
import { Loader } from 'components/Loader';

import { PriceListPackage } from './PriceListPackage';

const packagesTableHeaders: IHeaderConfig[] = [
	{ name: 'Name', required: true, left: true },
	{ name: 'Retail Value', required: true, left: true },
	{ name: 'Package Code', required: true, left: true },
	{
		name: 'Available for',
		left: true,
	},
];

interface IPriceListPackageProps extends IBackgroundOptionsVisibilityProps {
	priceListKey?: number;
	isPriceListPending: boolean;
	isPackageSelectionRequired?: boolean;
	setActiveTab: (tab: StudioPriceListTabs) => void;
}

export const PriceListPackages: FC<IPriceListPackageProps> = ({
	setActiveTab,
	priceListKey,
	isPriceListPending,
	updatePriceListStudio,
	isPackageSelectionRequired,
}) => {
	const [previewImageFile, setPreviewImageFile] = useState<File | null>(null);
	const [packagesUI, setPackagesUI] = useState<IPackageUI[]>([]);
	const [isReorder, setIsReorder] = useState(false);
	const [isPending, setIsPending] = useState(true);

	const initialStudioIsBackgroundRequired = useAppSelector(
		initialStudioIsBackgroundRequiredSelector
	);
	const initialRequiredPoseOptions = useAppSelector(
		initialRequiredPoseOptionsSelector
	);

	const lastPackageUI = packagesUI[packagesUI.length - 1];

	const updateIsRequiredPackageFirst = async (value: boolean) => {
		if (!updatePriceListStudio) return;

		const updatedField: IPatchBody = {
			value,
			op: 'replace',
			path: 'isPackageSelectionRequired',
		};

		const body: IPatchBody[] = [updatedField];

		await updatePriceListStudio(body);
	};

	const {
		checked,
		handleChange,
		isPending: isPendingRequiredPackage,
	} = useAsyncOptimizedCheckbox(
		isPackageSelectionRequired,
		updateIsRequiredPackageFirst
	);

	const getPackages = useCallback(async () => {
		try {
			if (!priceListKey) return;

			const data = await PackagesService.getPackages(priceListKey);

			const updatedPackagesUI = data.map((packageData) => {
				const priceListFormQuestionsUI =
					packageData.priceListStudioFulfilledPackageFormQuestions.map(
						(formQuestion) => ({
							...formQuestion,
							errors: initialPriceListFormQuestionErrors,
							entityKey: packageData.priceListStudioFulfilledPackageKey,
							formQuestionKey:
								formQuestion.priceListStudioFulfilledPackageFormQuestionKey,
						})
					);

				return {
					...packageData,
					errors: initialPackageErrors,
					priceListStudioFulfilledPackageFormQuestions:
						priceListFormQuestionsUI,
				};
			});

			setPackagesUI(updatedPackagesUI);
		} catch (error) {
			console.log(error);
		}

		setIsPending(false);
	}, [priceListKey]);

	const handleAddPackage = () => {
		if (!priceListKey) return;

		const initialSequence = packagesUI.length && lastPackageUI.sequence + 1;

		const newPackageUI: IPackageUI = {
			name: '',
			retailPrice: 0,
			description: '',
			referenceCode: '',
			previewImageUrl: null,
			ownPackagePointsAmount: 0,
			sequence: initialSequence,
			errors: initialPackageErrors,
			isWaiveShippingCharge: false,
			isIncludeImagesDownload: false,
			isBuildOwnPackageEnabled: false,
			availableFor: PoseAvailableFor.Individual,
			priceListStudioFulfilledKey: priceListKey,
			requiredPoseOptions: initialRequiredPoseOptions,
			priceListStudioFulfilledPackageFormQuestions: [],
			isBackgroundOptionRequired: initialStudioIsBackgroundRequired,
		};

		setPackagesUI([...packagesUI, newPackageUI]);
	};

	const handleSavePackage = async () => {
		if (!priceListKey) return;

		const {
			name,
			sequence,
			retailPrice,
			description,
			availableFor,
			referenceCode,
			requiredPoseOptions,
			isWaiveShippingCharge,
			ownPackagePointsAmount,
			isIncludeImagesDownload,
			isBuildOwnPackageEnabled,
			isBackgroundOptionRequired,
		} = lastPackageUI;

		const slicedPackagesUI = packagesUI.slice(0, -1);

		const nameValidationMessage = validateOptionName(name);
		const retailPriceValidationMessage = validateRetailValue(retailPrice);
		const descriptionValidationMessage = validateDescription(description);
		const referenceCodeValidationMessage = validateReferenceCode(referenceCode);
		const ownPackagePointsAmountValidationMessage = validatePoints(
			ownPackagePointsAmount
		);

		const isValid =
			!nameValidationMessage &&
			!retailPriceValidationMessage &&
			!descriptionValidationMessage &&
			!referenceCodeValidationMessage &&
			!ownPackagePointsAmountValidationMessage;

		if (!isValid) {
			const updatedPackageUI: IPackageUI = {
				...lastPackageUI,
				errors: {
					previewImageUrl: '',
					name: nameValidationMessage,
					retailPrice: retailPriceValidationMessage,
					description: descriptionValidationMessage,
					referenceCode: referenceCodeValidationMessage,
				},
			};

			setPackagesUI([...slicedPackagesUI, updatedPackageUI]);

			return;
		}

		try {
			const body: ICreatePackageBody = {
				name,
				sequence,
				retailPrice,
				description,
				availableFor,
				referenceCode,
				previewImageFile,
				requiredPoseOptions,
				isWaiveShippingCharge,
				ownPackagePointsAmount,
				isIncludeImagesDownload,
				isBuildOwnPackageEnabled,
				isBackgroundOptionRequired,
			};

			const data = await PackagesService.createPackage(priceListKey, body);

			const updatedPackageUI: IPackageUI = {
				...data,
				errors: initialPackageErrors,
				priceListStudioFulfilledPackageFormQuestions: [],
			};

			setPackagesUI([...slicedPackagesUI, updatedPackageUI]);
			setPreviewImageFile(null);
		} catch (error) {
			const { errors } = error as IErrorResponse;

			const updatedPackageUI: IPackageUI = {
				...lastPackageUI,
				errors: {
					...initialPackageErrors,
					...errors,
				},
			};

			setPackagesUI([...slicedPackagesUI, updatedPackageUI]);
		}
	};

	const updatePackage = async ({
		value,
		fieldKey,
		sequence,
		packageKey,
		validationMessage,
	}: IUpdatePackage) => {
		if (!packageKey || validationMessage) {
			const updatedPackagesUI = packagesUI.map((packageUI) =>
				packageUI.sequence === sequence
					? {
							...packageUI,
							[fieldKey]: value,
							errors: {
								...packageUI.errors,
								[fieldKey]: validationMessage,
							},
					  }
					: packageUI
			);

			setPackagesUI(updatedPackagesUI);
			return;
		}

		if (!packageKey) return;

		try {
			const updatedFiled: IPatchBody = {
				value,
				op: 'replace',
				path: fieldKey,
			};

			const body: IPatchBody[] = [updatedFiled];

			const data = await PackagesService.updatePackage(packageKey, body);

			const updatedPackagesUI = packagesUI.map((packageUI) =>
				packageUI.priceListStudioFulfilledPackageKey === packageKey
					? {
							...data,
							errors: initialPackageErrors,
							priceListStudioFulfilledPackageFormQuestions:
								packageUI.priceListStudioFulfilledPackageFormQuestions,
					  }
					: packageUI
			);

			setPackagesUI(updatedPackagesUI);
		} catch (error) {
			const { errors } = error as IErrorResponse;

			const updatedPackagesUI = packagesUI.map((packageUI) =>
				packageUI.sequence === sequence
					? {
							...packageUI,
							errors: {
								...packageUI.errors,
								...errors,
							},
					  }
					: packageUI
			);

			setPackagesUI(updatedPackagesUI);
		}
	};

	const changePackagesSequence = (payload: IChangeSequencePayload) => {
		const reorderedPackagesUI = changeEntitiesSequence({
			...payload,
			entities: packagesUI,
			fieldKey: 'priceListStudioFulfilledPackageKey',
		});

		setPackagesUI(reorderedPackagesUI);
		setIsReorder(true);
	};

	const reorderPackages = useCallback(async () => {
		if (!priceListKey || !isReorder) return;

		const body = packagesUI.reduce<Record<number, number>>(
			(acc, { sequence, priceListStudioFulfilledPackageKey }) =>
				priceListStudioFulfilledPackageKey
					? { ...acc, [priceListStudioFulfilledPackageKey]: sequence }
					: acc,
			{}
		);

		try {
			await PackagesService.reorderPackages(priceListKey, body);
		} catch (error) {
			console.log(error);
		}

		setIsReorder(false);
	}, [isReorder, priceListKey]);

	const uploadPackageImage = async ({
		sequence,
		imageFile,
		packageKey,
		validationMessage,
	}: IUploadPackageImage) => {
		if (validationMessage) {
			const updatedPackagesUI = packagesUI.map((packageUI) =>
				packageUI.sequence === sequence
					? {
							...packageUI,
							errors: {
								...packageUI.errors,
								previewImageUrl: validationMessage,
							},
					  }
					: packageUI
			);

			setPackagesUI(updatedPackagesUI);
			return;
		}

		if (!packageKey) {
			setPreviewImageFile(imageFile);
			return;
		}

		try {
			const data = await PackagesService.uploadPackageImage(
				packageKey,
				imageFile
			);

			const updatedPackagesUI = packagesUI.map((packageUI) =>
				packageUI.priceListStudioFulfilledPackageKey === packageKey
					? { ...packageUI, previewImageUrl: data }
					: packageUI
			);

			setPackagesUI(updatedPackagesUI);
		} catch (error) {
			console.log(error);
		}
	};

	const deletePackageImage = async (packageKey?: number) => {
		if (!packageKey) {
			setPreviewImageFile(null);
			return;
		}

		try {
			await PackagesService.deletePackageImage(packageKey);

			const updatedPackagesUI = packagesUI.map((packageUI) =>
				packageUI.priceListStudioFulfilledPackageKey === packageKey
					? { ...packageUI, previewImageUrl: null }
					: packageUI
			);

			setPackagesUI(updatedPackagesUI);
		} catch (error) {
			console.log(error);
		}
	};

	const deletePackage = async ({ sequence, packageKey }: IDeletePackage) => {
		const updatedPackagesUI = packagesUI.filter(
			(packageUI) => packageUI.sequence !== sequence
		);

		setPackagesUI(updatedPackagesUI);
		setPreviewImageFile(null);

		if (packageKey) {
			try {
				await PackagesService.deletePackage(packageKey);
			} catch (error) {
				console.log(error);
			}
		}
	};

	const clearPackageError = ({ sequence, fieldKey }: IClearPackageErrors) => {
		const updatedPackagesUI = packagesUI.map((packageUI) =>
			packageUI.sequence === sequence
				? {
						...packageUI,
						errors: {
							...packageUI.errors,
							[fieldKey]: '',
						},
				  }
				: packageUI
		);

		setPackagesUI(updatedPackagesUI);
	};

	const setPackageFormQuestionsUI = (
		entityKey: number,
		formQuestionsUI: IPriceListFormQuestionUI[]
	) => {
		const updatedPackagesUI = packagesUI.map((packageUI) =>
			packageUI.priceListStudioFulfilledPackageKey === entityKey
				? {
						...packageUI,
						priceListStudioFulfilledPackageFormQuestions: formQuestionsUI,
				  }
				: packageUI
		);

		setPackagesUI(updatedPackagesUI);
	};

	const createFormQuestion = async (
		packageKey: number,
		body: IGeneralFormQuestion
	) => {
		const data = await PackagesService.createPackageFormQuestion(
			packageKey,
			body
		);

		return {
			...data,
			errors: initialPriceListFormQuestionErrors,
			entityKey: data.priceListStudioFulfilledPackageKey,
			formQuestionKey: data.priceListStudioFulfilledPackageFormQuestionKey,
		};
	};

	const updateFormQuestion = async (
		formQuestionKey: number,
		body: IPatchBody[]
	) => {
		const data = await PackagesService.updatePackageFormQuestion(
			formQuestionKey,
			body
		);

		return {
			...data,
			errors: initialPriceListFormQuestionErrors,
			entityKey: data.priceListStudioFulfilledPackageKey,
			formQuestionKey: data.priceListStudioFulfilledPackageFormQuestionKey,
		};
	};

	const deleteFormQuestion = async (formQuestionKey: number) =>
		PackagesService.deletePackageFormQuestion(formQuestionKey);

	const reorderFormQuestions = async (
		packageKey: number,
		body: Record<number, number>
	) => PackagesService.reorderPackageFormQuestions(packageKey, body);

	const handleBack = () => {
		setActiveTab(StudioPriceListTabs.PriceListOptions);
	};

	const handleNext = () => {
		setActiveTab(StudioPriceListTabs.ALaCarte);
	};

	useEffect(() => {
		void getPackages();
	}, [getPackages]);

	useEffect(() => {
		void reorderPackages();
	}, [reorderPackages]);

	const PackagesList = packagesUI.map((packageUI) => (
		<PriceListPackage
			packageUI={packageUI}
			deletePackage={deletePackage}
			updatePackage={updatePackage}
			previewImageFile={previewImageFile}
			clearPackageError={clearPackageError}
			deleteFormQuestion={deleteFormQuestion}
			createFormQuestion={createFormQuestion}
			uploadPackageImage={uploadPackageImage}
			updateFormQuestion={updateFormQuestion}
			deletePackageImage={deletePackageImage}
			reorderFormQuestions={reorderFormQuestions}
			setFormQuestionsUI={setPackageFormQuestionsUI}
			changePackagesSequence={changePackagesSequence}
			key={packageUI.priceListStudioFulfilledPackageKey || packageUI.sequence}
		/>
	));

	const showSaveBtn =
		!!packagesUI.length && !lastPackageUI?.priceListStudioFulfilledPackageKey;

	const { btnValue, btnClassName, btnClickHandler } =
		useCreatePriceListOptionBtn({
			showSaveBtn,
			optionName: 'Package',
			handleAdd: handleAddPackage,
			handleSave: handleSavePackage,
		});

	const showLoader = isPending || isPriceListPending;
	const showPriceTable = !showLoader && !!packagesUI.length;
	const showNoItemsFound = !showLoader && !packagesUI.length;

	return (
		<>
			<div className="package-carte-title">
				<span className="price-package-title">Packages</span>
				<Checkbox
					checked={checked}
					handleChange={handleChange}
					label="Require Package First"
					id="isPackageSelectionRequired"
					disabled={isPendingRequiredPackage}
				/>
			</div>
			<div className="price-container">
				{showLoader && <Loader />}
				{showPriceTable && (
					<PriceTable
						headers={packagesTableHeaders}
						className="price-table-packages"
					>
						{PackagesList}
					</PriceTable>
				)}
				{showNoItemsFound && <NoItemsFound title="packages" />}
				<Button
					value={btnValue}
					handleClick={btnClickHandler}
					className={`price-add-back ${btnClassName}`}
				/>
				<PriceListControls handleBack={handleBack} handleNext={handleNext} />
			</div>
		</>
	);
};
