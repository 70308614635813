import { IW9BillingInfoResponse } from 'api/models/responses/studioBilling/w9BillingInfo';
import { IBankAccountResponse } from 'api/models/responses/studioBilling/bankAccount';
import { IW9BillingInfoBody } from 'api/models/requests/studioBilling/w9BillingInfo';
import { IBankAccountBody } from 'api/models/requests/studioBilling/bankAccount';
import { IBankAccountUpdateResponse } from 'api/models/responses/studioBilling/bankAccountUpdate';
import { IBillingInfo } from 'api/models/responses/studioBilling/IBillingInfo';
import { IBillingCardInfo } from 'api/models/requests/studio/createStudio';

import { studioBillingUrls } from 'constants/apiUrls/studioBillingUrls';
import { api } from 'config/api';

class StudioBillingService {
	async createBankACHInformation(
		bankACHBody: IBankAccountBody
	): Promise<IBankAccountResponse> {
		const { data } = await api.post<IBankAccountResponse>(
			studioBillingUrls.createBankACHInformation,
			bankACHBody
		);

		return data;
	}

	async updateW9BillingInfo(
		w9BillingBody: IW9BillingInfoBody
	): Promise<IW9BillingInfoResponse> {
		const { data } = await api.put<IW9BillingInfoResponse>(
			studioBillingUrls.updateW9BillingInfo,
			w9BillingBody
		);

		return data;
	}

	async getBankACHInfo(): Promise<IBankAccountResponse | null> {
		const { data } = await api.get<IBankAccountResponse>(
			studioBillingUrls.createBankACHInformation
		);

		return data;
	}

	async updateBankACHInformation(
		bankACHBody: IBankAccountBody
	): Promise<IBankAccountUpdateResponse | null> {
		const { data } = await api.put<IBankAccountUpdateResponse>(
			studioBillingUrls.createBankACHInformation,
			bankACHBody
		);

		return data;
	}

	async getW9Info(): Promise<IW9BillingInfoResponse | null> {
		const { data } = await api.get<IW9BillingInfoResponse>(
			studioBillingUrls.updateW9BillingInfo
		);

		return data;
	}

	async getBillingInfo(): Promise<IBillingInfo | null> {
		const { data } = await api.get<IBillingInfo>(studioBillingUrls.billingInfo);

		return data;
	}

	async updateCardDetails(
		body: IBillingCardInfo
	): Promise<IBillingInfo | null> {
		const { data } = await api.put<IBillingInfo>(
			studioBillingUrls.billingInfo,
			body
		);

		return data;
	}
}

export default new StudioBillingService();
