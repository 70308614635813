import { object, string, AnySchema } from 'yup';

import { nameValidationMessage } from 'constants/organizations/validation';
import { requiredFieldMessage } from 'constants/general/validation/generalMessages';
import { organizationNameRegEx } from 'constants/organizations/validation';

import { ICreateJourneyPresetValues } from 'store/journeyPresets/types';

export const createJourneyPresetScheme = object().shape<
	Record<keyof ICreateJourneyPresetValues, AnySchema>
>({
	journey: string(),
	journeyName: string()
		.required(requiredFieldMessage)
		.matches(organizationNameRegEx, nameValidationMessage),
	projectType: string().required(requiredFieldMessage),
});
