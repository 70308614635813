import { FC, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import cn from 'classnames';

import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useToastify } from 'hooks/useToastify';

import { Modal, ModalRef } from 'components/Modal';
import { Loader } from 'components/Loader';

import { imageUploadTypes } from 'constants/images/validation/imageUploadTypes';
import {
	ErrorTypes,
	IErrorResponse,
} from 'api/models/responses/general/errorResponse';
import {
	isUploadImagesPendingSelector,
	setIsUploadImagesPending,
	uploadImageAsync,
} from 'store/imageGallery';

import { UploadProgress } from '../UploadProgress';

export const UploadNewMedia: FC = () => {
	const [uploadedImagesCount, setUploadedImagesCount] = useState(0);
	const [filesCount, setFilesCount] = useState(0);

	const isUploadImagesPending = useAppSelector(isUploadImagesPendingSelector);

	const uploadMediaProgressModalRef = useRef<ModalRef>(null);

	const dispatch = useAppDispatch();

	const { showErrorToastify } = useToastify();

	const showUploadMediaProgressModal = () => {
		uploadMediaProgressModalRef?.current?.open();
	};

	const hideUploadMediaProgressModal = () => {
		uploadMediaProgressModalRef?.current?.close();
	};

	const uploadImages = async (imageFiles: File[]) => {
		dispatch(setIsUploadImagesPending(true));

		const promises = imageFiles.map(async (imageFile) => {
			try {
				await dispatch(uploadImageAsync(imageFile));

				setUploadedImagesCount((prevCount) => prevCount + 1);
			} catch (error) {
				const { type, errors } = error as IErrorResponse;

				if (type === ErrorTypes.BusinessError) {
					const { studioGalleryImage } = errors;

					if (!studioGalleryImage) return;

					showErrorToastify({ title: studioGalleryImage[0] });
				}
			}
		});

		await Promise.all(promises);

		dispatch(setIsUploadImagesPending(false));
	};

	const onDrop = (acceptedFiles: File[]) => {
		setFilesCount(acceptedFiles.length);
		setUploadedImagesCount(0);
		void uploadImages(acceptedFiles);
		showUploadMediaProgressModal();
	};

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		multiple: true,
		accept: imageUploadTypes,
	});

	return (
		<>
			<div className="media-section">
				<h4 className="media-section-title">Upload new Media</h4>
				{isUploadImagesPending ? (
					<Loader />
				) : (
					<div
						className={cn('media-drop', {
							'media-drop-active': isDragActive,
						})}
						{...getRootProps()}
					>
						<input {...getInputProps()} />
						<span className="media-drop-title">Drop Files to Upload</span>
						<span className="media-drop-divider">or</span>
						<button className="btn-primary media-drop-btn">Select Files</button>
					</div>
				)}
			</div>
			<Modal
				title="Uploading your Media"
				ref={uploadMediaProgressModalRef}
				subtitle="Please keep this window open while we upload your media. Once upload is complete, click OK to proceed."
			>
				<UploadProgress
					filesCount={filesCount}
					uploadedImagesCount={uploadedImagesCount}
					hideUploadImagesProgressModal={hideUploadMediaProgressModal}
				/>
			</Modal>
		</>
	);
};
