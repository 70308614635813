import { Link, useNavigate } from 'react-router-dom';
import { CellProps, Column } from 'react-table';
import { FC, useEffect } from 'react';

import { useJourneyPresetBreadcrumbs } from 'pages/JourneyPresets/hooks/useJourneyPresetBreadcrumbs';
import { usePaginatedJourneyPresets } from 'pages/JourneyPresets/hooks/usePaginatedJourneyPresets';

import { GENERAL_SELECT_OPTION } from 'constants/projects/selectOptions/generalFilterSelectOption';
import { JourneyPresetsSortLabels } from 'constants/journeyPresets/journeyPresetsSortLabels';
import { JourneyPresetActionMenu } from 'constants/journeyPresets/journeyPresetActionMenu';
import { projectTypesUI } from 'constants/projects/projectTypesUI';
import { ROUTES } from 'constants/ROUTES';

import { ColumnLabelContainer } from 'components/Table/ColumnLabelContainer';
import { CellActionMenu } from 'components/Table/CellActionMenu';
import { Button } from 'components/FormControls/Button';

import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useActionMenu } from 'hooks/useActionMenu';

import { IBasePreset } from 'api/models/responses/presets/presets';
import {
	setFilterByJourney,
	setFilterByProjectType,
	deleteJourneyPresetAsync,
	setCopiedJourneyPresetKey,
} from 'store/journeyPresets';

import { JourneyPresetsListContainer } from './components/JourneyPresetsListContainer';
import { JourneyPresetsHeader } from './components/JourneyPresetsHeader';

const journeyPresetActionMenuList = Object.values(JourneyPresetActionMenu);

export const JourneyPresets: FC = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const { actionMenuId, setActionMenuId } = useActionMenu();

	const { orderBy, setSortParams, orderDirection, getPaginatedJourneyPresets } =
		usePaginatedJourneyPresets();

	useJourneyPresetBreadcrumbs();

	const handleNewJourneyPreset = () => {
		navigate(ROUTES.PRESETS.CREATE_PRESET);
	};

	const handleDeleteJourneyPreset = (presetKey: number) => {
		void dispatch(deleteJourneyPresetAsync(presetKey));
	};

	const handleCopyJourneyPreset = (presetKey: number) => {
		dispatch(setCopiedJourneyPresetKey(presetKey));

		navigate(ROUTES.PRESETS.CREATE_PRESET_DETAILS);
	};

	const handleActionMenuItemClick = (
		presetKey: number,
		menuItem: JourneyPresetActionMenu
	) => {
		if (menuItem === JourneyPresetActionMenu.DeletePermanently) {
			return handleDeleteJourneyPreset(presetKey);
		}

		if (menuItem === JourneyPresetActionMenu.Copy) {
			return handleCopyJourneyPreset(presetKey);
		}
	};

	useEffect(() => {
		dispatch(setFilterByJourney(GENERAL_SELECT_OPTION));
		dispatch(setFilterByProjectType(GENERAL_SELECT_OPTION));
	}, []);

	const columns: Column<IBasePreset>[] = [
		{
			Header: (
				<ColumnLabelContainer<JourneyPresetsSortLabels>
					label="Name"
					orderBy={orderBy}
					setSortParams={setSortParams}
					orderDirection={orderDirection}
					sortLabel={JourneyPresetsSortLabels.Name}
				/>
			),
			accessor: 'presetName',
			Cell: ({ row }: CellProps<IBasePreset>) => {
				const { presetKey, presetName } = row.original;

				return (
					<Link
						to={`${ROUTES.PRESETS.PRESETS}/${presetKey}`}
						className="card-table-link"
						title={presetName}
					>
						{presetName}
					</Link>
				);
			},
		},
		{
			Header: (
				<ColumnLabelContainer<JourneyPresetsSortLabels>
					orderBy={orderBy}
					label="Project Type"
					setSortParams={setSortParams}
					orderDirection={orderDirection}
					sortLabel={JourneyPresetsSortLabels.ProjectType}
				/>
			),
			accessor: 'projectType',
			Cell: ({ value }: CellProps<IBasePreset, IBasePreset['projectType']>) => (
				<>{projectTypesUI[value]}</>
			),
		},
		{
			Header: (
				<ColumnLabelContainer<JourneyPresetsSortLabels>
					label="Journey"
					orderBy={orderBy}
					setSortParams={setSortParams}
					orderDirection={orderDirection}
					sortLabel={JourneyPresetsSortLabels.Journey}
				/>
			),
			accessor: 'journey',
			Cell: ({ value }: CellProps<IBasePreset, IBasePreset['journey']>) => (
				<>Journey {value}</>
			),
		},
		{
			id: 'actionMenu',
			Cell: ({ row }: CellProps<IBasePreset>) => {
				const { presetKey } = row.original;

				return (
					<CellActionMenu
						menuList={journeyPresetActionMenuList}
						showActionMenu={actionMenuId === presetKey}
						handleMenuItemClick={(value) =>
							handleActionMenuItemClick(presetKey, value)
						}
						setActiveActionMenu={() => setActionMenuId(presetKey)}
					/>
				);
			},
		},
	];

	return (
		<div className="org">
			<JourneyPresetsHeader handleNewJourneyPreset={handleNewJourneyPreset} />
			<JourneyPresetsListContainer
				columns={columns}
				getPaginatedJourneyPresets={getPaginatedJourneyPresets}
			/>
			<Button
				value="+ New Preset"
				handleClick={handleNewJourneyPreset}
				className="btn-primary journey-mob-show"
			/>
		</div>
	);
};
