import { SingleValue } from 'react-select';
import { useFormikContext } from 'formik';
import { FC } from 'react';

import { projectJourneysSelectOptions } from 'constants/projects/selectOptions/projectJourneysSelectOptions';
import { targetSelectOptions } from 'constants/campaigns/targetSelectOptions';

import { ProjectJourneys } from 'api/models/requests/projects/projectJourneys';
import { CampaignsTarget } from 'api/models/responses/campaigns/campaign';
import { ICopyCampaignBody } from 'api/models/requests/campaigns';

import { SelectComponent } from 'components/FormControls/Select';
import { InputGrid } from 'components/FormControls/InputGrid';

import { ISelectOption } from 'types/ui/select';

export const CampaignCopyForm: FC = () => {
	const { values, errors, touched, setFieldValue, handleChange } =
		useFormikContext<ICopyCampaignBody>();

	const handleChangeTarget = (
		option: SingleValue<ISelectOption<CampaignsTarget>>
	) => {
		if (!option) return;

		void setFieldValue('target', option.value);
	};

	const handleChangeJourney = (
		option: SingleValue<ISelectOption<ProjectJourneys>>
	) => {
		if (!option) return;

		void setFieldValue('journey', option.value);
	};

	return (
		<div className="campaign-modal-copy">
			<label className="label">
				Campaign Name <i className="grid-required">*</i>
			</label>
			<InputGrid
				row
				id="name"
				required={false}
				error={errors.name}
				className="autorow"
				value={values.name}
				touched={touched.name}
				handleChange={handleChange}
				placeholder="Campaign Name"
			/>
			<SelectComponent
				required
				value={values.target}
				label="Campaign Target"
				onChange={handleChangeTarget}
				selectOptions={targetSelectOptions}
			/>
			<SelectComponent
				required
				value={values.journey}
				label="Project Journey"
				onChange={handleChangeJourney}
				selectOptions={projectJourneysSelectOptions}
			/>
		</div>
	);
};
