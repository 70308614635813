import { useCallback, useEffect, useRef, useState } from 'react';
import { useToggleSection } from 'hooks/useToggleSection';
import moment, { parseTwoDigitYear, utc } from 'moment';
import { Formik } from 'formik';

import { Payments } from 'types/ui/payments';

import StudioBillingService from 'api/services/StudioBillingService';
import { IBillingInfo } from 'api/models/responses/studioBilling/IBillingInfo';
import { IBillingCardInfo } from 'api/models/requests/studio/createStudio';

import { Button } from 'components/FormControls/Button';
import { ToggleSection } from 'components/ToggleSection';
import { Modal, ModalRef } from 'components/Modal';

import {
	IUpdateCardForm,
	UpdateCardDetails,
} from 'pages/Accounting/modals/UpdateCardDetails';

export const BillingInformation = () => {
	const { isOpen, handleToggle } = useToggleSection(true);
	const [billingInfo, setBillingInfo] = useState<IBillingInfo | null>(null);

	const getBillingInfo = useCallback(async () => {
		try {
			const data = await StudioBillingService.getBillingInfo();
			setBillingInfo(data);
		} catch (error) {
			console.log(error);
		}
	}, []);

	useEffect(() => {
		void getBillingInfo();
	}, [getBillingInfo]);

	const updateCardRef = useRef<ModalRef>(null);

	const showUpdateCardModal = () => {
		updateCardRef?.current?.open();
	};

	const hideUpdateCardModal = () => {
		updateCardRef?.current?.close();
	};

	const expireDate =
		billingInfo?.expires &&
		moment(utc(billingInfo?.expires).toDate()).format('MM/DD');

	//------------
	const initialValues: IUpdateCardForm = {
		cardType: Payments.VISA,
		cardNumber: '',
		expiration: '',
		verificationCode: '',
		billingZip: '',
	};

	const onSubmit = async (values: IUpdateCardForm) => {
		const { cardType, cardNumber, expiration, verificationCode, billingZip } =
			values;

		const [expireMonth, expireYear] = expiration.split('/');

		const fullYear = parseTwoDigitYear(expireYear);

		const billingCardInfo: IBillingCardInfo = {
			cardType,
			cardNumber,
			expireMonth: +expireMonth,
			expireYear: fullYear,
			verificationCode,
			billingZip,
		};

		try {
			const data = await StudioBillingService.updateCardDetails(
				billingCardInfo
			);

			if (!data) return;

			setBillingInfo(data);
			hideUpdateCardModal();
		} catch (error) {
			console.log(error, 'error');
		}
	};

	return (
		<div className="accing-toggle-section">
			<ToggleSection
				title="Billing Information"
				isOpen={isOpen}
				className="accing-toggle-header"
				handleToggle={handleToggle}
			>
				<div className="accing-wrapper">
					<span className="accing-title">
						Will use for any fees and balances for this studio.
					</span>
					<div className="accing-list-wrapper">
						<ul className="accing-list">
							<li className="accing-item">
								Card Type: <span>{billingInfo?.cardType}</span>
							</li>
							<li className="accing-item">
								Card Number: <span>**** **** **** {billingInfo?.lastFour}</span>
							</li>
							<li className="accing-item">
								Expiration Date: <span>{expireDate}</span>
							</li>
						</ul>
						<Button
							className="btn-secondary accing-btn"
							value="Update Credit Card"
							handleClick={showUpdateCardModal}
						/>
					</div>
				</div>
			</ToggleSection>
			<Modal
				title="Update Card Details"
				subtitle="Will use this card for any fees and balances for this studio."
				ref={updateCardRef}
			>
				<Formik onSubmit={onSubmit} initialValues={initialValues}>
					<UpdateCardDetails closeModal={hideUpdateCardModal} />
				</Formik>
			</Modal>
		</div>
	);
};
