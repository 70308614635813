export const ROUTES = {
	DASHBOARD: '/',
	STUDIO_STEPS: {
		CREATE_STUDIO: '/studio/create-studio',
		SELECT_PLAN: '/studio/select-plan',
		GET_PAID: '/studio/get-paid',
	},
	ORGANIZATIONS: {
		ORGANIZATIONS: '/organizations',
		ACTIVE_ORGANIZATIONS: '/active-organizations',
		ARCHIVED_ORGANIZATIONS: '/archived-organizations',
		ORGANIZATION_DETAILS: '/organizations/:organizationId',
	},
	PROJECTS: {
		PROJECTS: '/projects',
		CREATE_PROJECT: '/create-project',
		PROJECT_DETAILS: '/projects/:projectId',
		CREATE_BLANK_PROJECT: '/create-blank-project',
		CREATE_PROJECT_FROM_PRESET: '/create-project-from-preset',
	},
	PRESETS: {
		PRESETS: '/presets',
		CREATE_PRESET: '/create-preset',
		PRESET_DETAILS: '/presets/:journeyPresetId',
		CREATE_PRESET_DETAILS: '/create-preset-details',
	},
	PRICE_LISTS: {
		PRICE_LISTS: '/price-lists',
		PRICE_LISTS_IMAGO: '/price-lists-imago',
		PRICE_LISTS_STUDIO: '/price-lists-studio',
		PRICE_LISTS_IMAGO_DETAILS: '/price-lists-imago/:priceListId',
		PRICE_LISTS_STUDIO_DETAILS: '/price-lists-studio/:priceListId',
	},
	CAMPAIGNS: {
		CAMPAIGNS: '/campaigns',
		BROADCASTS: '/broadcasts',
		CREATE_BROADCAST: '/create-broadcast',
		CAMPAIGN_DETAILS: '/campaigns/:campaignId',
		BROADCAST_DETAILS: '/broadcasts/:broadcastId',
	},
	WATERMARK_MEDIA: {
		WATERMARK_MEDIA: '/watermark-media',
		MEDIA_LIBRARY: '/media-library',
	},
	PROMOTIONS: {
		PROMOTIONS: '/promotions',
		CREATE_PROMOTION: '/create-promotion',
		PROMOTIONS_DETAILS: '/promotions/:promotionId',
	},
	MARKETING: '/marketing',
	ACCOUNTING: '/accounting',
};
