import { FC, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';

import { projectTypesSelectOptions } from 'constants/projects/selectOptions/projectTypesSelectOptions';
import { journeyTimelines } from 'constants/projects/timeline/journeyTimelines';
import { projectJourneyInfo } from 'constants/projects/projectJourneyInfo';
import { presetsUrls } from 'constants/apiUrls/presetsUrls';

import { getSubjectFormQuestionsUI } from 'utils/subjectFormQuestions/getSubjectFormQuestionsUI';
import { getPriceListSelectOptionKey } from 'utils/priceLists/getPriceListSelectOptionKey';
import { getPriceListsSelectOptions } from 'utils/priceLists/getPriceListsSelectOptions';

import { JourneyPresetPlaceholder } from 'components/Journey/JourneyPresetPlaceholder';
import { ISubjectFormQuestionUI } from 'components/SubjectFormQuestions/types';
import { SubjectFormQuestions } from 'components/SubjectFormQuestions';
import { JourneyTimeline } from 'components/Journey/JourneyTimeline';
import { SelectComponent } from 'components/FormControls/Select';
import { ISaleValues } from 'components/Sales/Sale/types';
import { salesUI } from 'constants/projects/salesUI';
import { Sale } from 'components/Sales/Sale';
import { Loader } from 'components/Loader';

import SubjectFormQuestionsService from 'api/services/SubjectFormQuestionsService';
import { IPreset, IPresetSale } from 'api/models/responses/presets/presets';
import PresetsService from 'api/services/PresetsService';

import { ICreateProjectFromPresetValues } from 'pages/Projects/pages/CreateProjectFromPreset';
import presetHeaderImage from 'images/projects/preset-header.svg';

import { PresetDetailsHeader } from './components/PresetDetailsHeader';
import { Marketing } from './components/Marketing';
import { useAppSelector } from 'hooks/redux/useAppSelector';
import { selectedJourneyPresetSelector } from 'store/journeyPresets';

export const PresetDetails: FC = () => {
	const [preset, setPreset] = useState<IPreset | null>(null);
	const [isPresetPending, setIsPresetPending] = useState(false);

	const [presetSales, setPresetSales] = useState<IPresetSale[]>([]);
	const [isPresetSalesPending, setIsPresetSalesPending] = useState(false);

	const [subjectFormQuestionsUI, setSubjectFormQuestionsUI] = useState<
		ISubjectFormQuestionUI[]
	>([]);
	const [isFormQuestionsPending, setIsFormQuestionsPending] = useState(false);

	const selectedJourneyPreset = useAppSelector(selectedJourneyPresetSelector);

	const getPresetDetails = async (presetKey: number) => {
		setIsPresetPending(true);

		try {
			const data = await PresetsService.getPreset(presetKey);

			setPreset(data);
		} catch (error) {
			console.log(error);
		}

		setIsPresetPending(false);
	};

	const getPresetSales = async (presetKey: number) => {
		setIsPresetSalesPending(true);

		try {
			const data = await PresetsService.getPresetSales(presetKey);

			setPresetSales(data);
		} catch (error) {
			console.log(error);
		}

		setIsPresetSalesPending(false);
	};

	const getPresetSubjectFormQuestions = async (presetKey: number) => {
		setIsFormQuestionsPending(true);

		try {
			const data = await SubjectFormQuestionsService.getSubjectFormQuestions(
				presetsUrls.getPresetsFormQuestions(presetKey)
			);

			setSubjectFormQuestionsUI(getSubjectFormQuestionsUI(data));
		} catch (error) {
			console.log(error);
		}

		setIsFormQuestionsPending(false);
	};

	const isPending =
		isPresetPending || isPresetSalesPending || isFormQuestionsPending;

	const presetKey = selectedJourneyPreset?.presetKey;

	console.log('presetKey', presetKey);

	useEffect(() => {
		if (!presetKey) return;

		void getPresetDetails(presetKey);
		void getPresetSales(presetKey);
		void getPresetSubjectFormQuestions(presetKey);
	}, [presetKey]);

	if (isPending) {
		return <Loader />;
	}

	if (!selectedJourneyPreset || !preset) {
		return (
			<div className="preset-back">
				<JourneyPresetPlaceholder />
				<span className="preset-placeholder-back" />
			</div>
		);
	}

	const {
		journeyText,
		journeyTitle,
		journeySubText,
		journeyProjectPresetImage,
	} = journeyTimelines[selectedJourneyPreset.journey];

	const { description } = projectJourneyInfo[selectedJourneyPreset.journey];

	const {
		presetName,
		projectType,
		leadCampaign,
		buyerCampaign,
		prospectCampaign,
		isMarketingEnabled,
	} = preset;

	const initialPresetSales: ISaleValues[] = presetSales.map(
		({
			saleType,
			priceList,
			condition,
			projectGreeting,
			saleExpiresInDays,
			isKioskModeEnabled,
			orderNotificationEmail,
			orderConfirmationMessage,
		}) => {
			const priceListKey =
				priceList &&
				getPriceListSelectOptionKey(
					priceList.priceListKey,
					priceList.fulfillmentType
				);

			return {
				saleType,
				condition,
				priceListKey,
				projectGreeting,
				isKioskModeEnabled,
				orderNotificationEmail,
				orderConfirmationMessage,
				saleExpiresInDays: saleExpiresInDays.toString(),
			};
		}
	);

	const SalesList = initialPresetSales.map((saleValues, index) => {
		const saleType = saleValues.saleType;
		const presetSale = presetSales[index];

		if (!saleType) return null;

		const salePriceListsSelectOptions = getPriceListsSelectOptions(
			presetSale.allowedPriceLists
		);

		const saleUI = saleType && salesUI[saleType];

		return (
			<Sale
				errors={{}}
				touched={{}}
				disabledSale
				saleUI={saleUI}
				values={saleValues}
				saleType={saleType}
				updateSale={() => {}}
				key={saleValues.saleType}
				priceListsSelectOptions={salePriceListsSelectOptions}
			/>
		);
	});

	return (
		<div className="preset-form preset-form-sticky">
			<div className="preset-header-sticky">
				<PresetDetailsHeader presetInfo={description} presetName={presetName} />
				<div className="preset-placeholder-sticky">
					<img
						alt="preset"
						src={presetHeaderImage}
						className="preset-header-img"
					/>
				</div>
			</div>
			<div className="preset-back">
				<span className="preset-placeholder" />
				<div className="preset">
					<div className="preset-section-wrapper preset-wrapper-header">
						<SelectComponent
							disabled
							label="Project Type"
							onChange={() => {}}
							value={projectType}
							className="select-required"
							selectOptions={projectTypesSelectOptions}
						/>
					</div>
					<div className="preset-journey">
						<JourneyTimeline
							journeyText={journeyText}
							journeyTitle={journeyTitle}
							journeySubText={journeySubText}
							journeyImage={journeyProjectPresetImage}
						/>
					</div>
					{SalesList}
					<Marketing
						leadCampaignName={leadCampaign.name}
						isMarketingEnable={isMarketingEnabled}
						buyerCampaignName={buyerCampaign.name}
						prospectCampaignName={prospectCampaign.name}
					/>
					<SubjectFormQuestions
						title="Form"
						clearError={() => {}}
						createAvailable={false}
						handleUpdate={() => {}}
						handleCreate={() => {}}
						deleteFormRow={() => {}}
						changeSequence={() => {}}
						subjectFormQuestions={subjectFormQuestionsUI}
					/>
				</div>
			</div>
		</div>
	);
};
